import React from 'react';
// import { Text } from '@team-monite/ui-kit-react';
// import styled from '@emotion/styled';
import  styles from './styles.module.scss';
import { Typography } from '@mui/material';



// const Wrapper = styled.div`
//   flex: 0 0 auto;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 28px;
//   background: #FFFFFF;
//   box-shadow: 0px 4px 21px rgba(204, 204, 204, 0.25);
//   padding: 18px 0;
// `;

// const TextWrapper = styled(Text)`
//     font-size: 32px;
//     font-weight: 600;
//     line-height: 40px;
// `;

type PageHeaderProps = {
  title: string;
  extra?: React.ReactNode;
  extratwo?:React.ReactNode;
  extrathere?:React.ReactNode;
};
const PageHeader = ({ title, extra}:any) => {
  return (
    <div  className={styles.main_tesffsffs}>
      
      <div>
        <h1 className={styles.hedingclass}>
          {title}
        </h1>
      </div>
      {extra ? <aside>{extra}</aside>: null}
    </div>
  );
};

export default PageHeader;
