export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    REGISTER_V2_REQUEST: 'USERS_V2_REGISTER_REQUEST',
    REGISTER_V2_SUCCESS: 'USERS_V2_REGISTER_SUCCESS',
    REGISTER_V2_FAILURE: 'USERS_V2_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    UPDATEPASSWARD_REQUEST: 'UPDATEPASSWARD_REQUEST',
    UPDATEPASSWARD_SUCCESS: 'UPDATEPASSWARD_SUCCESS',
    UPDATEPASSWARD_FAILURE: 'UPDATEPASSWARD_FAILURE',

    FORGOTPASSWARD_REQUEST: 'FORGOTPASSWARD_REQUEST',
    FORGOTPASSWARD_SUCCESS: 'FORGOTPASSWARD_SUCCESS',
    FORGOTPASSWARD_FAILURE: 'FORGOTPASSWARD_FAILURE',

    VERIFYTOKEN_REQUEST: 'VERIFYTOKEN_REQUEST',
    VERIFYTOKEN_SUCCESS: 'VERIFYTOKEN_SUCCESS',
    VERIFYTOKEN_FAILURE: 'VERIFYTOKEN_FAILURE',

    CHANGEPASSWARD_REQUEST: 'CHANGEPASSWARD_REQUEST',
    CHANGEPASSWARD_SUCCESS: 'CHANGEPASSWARD_SUCCESS',
    CHANGEPASSWARD_FAILURE: 'CHANGEPASSWARD_FAILURE',

    GETOTP_SUCCESS: 'GETOTP_SUCCESS',
    LOGOUT: 'USERS_LOGOUT',

    REGISTER_INVITE_REQUEST: 'USERS_REGISTER_INVITE_REQUEST',
    REGISTER_INVITE_SUCCESS: 'USERS_REGISTER_INVITE_SUCCESS',
    REGISTER_INVITE_FAILURE: 'USERS_REGISTER_INVITE_FAILURE',

    SEND_OTP_REQUEST:"SEND_OTP_REQUEST",
    SEND_OTP_SUCCESS:"SEND_OTP_SUCCESS",
    SEND_OTP_FAILURE:"SEND_OTP_FAILURE",

    RESEND_OTP_REQUEST:"RESEND_OTP_REQUEST",
    RESEND_OTP_SUCCESS:"RESEND_OTP_SUCCESS",
    RESEND_OTP_FAILURE:"RESEND_OTP_FAILURE",
    // loginOtpVerify
    LOGIN_OTP_VERIFY_REQUEST:"LOGIN_OTP_VERIFY_REQUEST",
    LOGIN_OTP_VERIFY_SUCCESS:"LOGIN_OTP_VERIFY_SUCCESS",
    LOGIN_OTP_VERIFY_FAILURE:"LOGIN_OTP_VERIFY_FAILURE",

    SAVE_CURRENCIES_REQUEST:"SAVE_CURRENCIES_REQUEST",
    SAVE_CURRENCIES_SUCCESS:"SAVE_CURRENCIES_SUCCESS",
    SAVE_CURRENCIES_FAILURE:"SAVE_CURRENCIES_FAILURE"
}