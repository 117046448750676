import { authHeader, handleResponse } from '../_helpers';
import { getEnvironment } from '../_helpers/environmentProperties';

const baseURL =
  getEnvironment() === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_STAGING_API_URL;

export const settingService = {
  getBusinessDeails,
  getDocuments,
  storeDocuments,
  submitDocuments,
  getEntityDocuments,
  submitEntityDocuments,
  deleteEntityDocuments,
  getUserDocuments,
  getInviteDocuments,
  getRolsDocuments,
  getPermissionsDocuments,
  deleteUser,
  updateUser,
  getUserDetail,
  findUserDetail,
  deleteInactiveUser,
  delete_document,
  getTeamDocuments,
  Team_cerete,
  getTeamById,
  deleteInactiveTaem,
  updateTeam,
  getUserteamDocuments,
};

// get setting types api
function getBusinessDeails(entity_id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/business-details/${entity_id}`, requestOptions).then(
    handleResponse
  );
}

function getDocuments(req) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/get-documents/${req.id}`, requestOptions).then(
    handleResponse
  );
}

function storeDocuments(DocumentRequest) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(DocumentRequest),
  };
  return fetch(`${baseURL}/store-documents`, requestOptions).then(
    handleResponse
  );
}

function submitDocuments(req) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/submit-documents/${req.id}`, requestOptions).then(
    handleResponse
  );
}

function submitEntityDocuments() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/submit-documents`, requestOptions).then(
    handleResponse
  );
}

function deleteEntityDocuments(req) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/delete-document/${req.id}`, requestOptions).then(
    handleResponse
  );
}
function delete_document(req) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/delete-document-file/${req}`, requestOptions).then(
    handleResponse
  );
}
function getEntityDocuments() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/get-documents/`, requestOptions).then(
    handleResponse
  );
}
function getRolsDocuments() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/get-user-roles`, requestOptions).then(
    handleResponse
  );
}
function getPermissionsDocuments() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/get-permissions/`, requestOptions).then(
    handleResponse
  );
}
function getUserDocuments(data) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  console.log(
    `${baseURL}/get-business-users/`,
    requestOptions,
    'agfaagaggaggga'
  );
  return fetch(`${baseURL}/get-business-users?${data}`, requestOptions).then(
    handleResponse
  );
}
function getUserteamDocuments(data) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  console.log(
    `${baseURL}/get-business-users/`,
    requestOptions,
    'agfaagaggaggga'
  );
  return fetch(`${baseURL}/team/get-team-by-id/${data}`, requestOptions).then(
    handleResponse
  );
}
function getTeamDocuments() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/team/get-team`, requestOptions).then(handleResponse);
}
function getInviteDocuments(req) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(req),
  };
  return fetch(`${process.env.REACT_APP_API_URL}/invite-users-to-niobi/portal`, requestOptions).then(
    handleResponse
  );
}

function deleteUser(req) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${req}`, requestOptions).then(handleResponse);
}

function getUserDetail(req) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/get-user/${req.id}`, requestOptions).then(
    handleResponse
  );
}

function updateUser(req) {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(req),
  };
  return fetch(`${baseURL}/update-user`, requestOptions).then(handleResponse);
}

function findUserDetail(req) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/get-user/${req.id}`, requestOptions).then(
    handleResponse
  );
}

function deleteInactiveUser(req) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${req}`, requestOptions).then(handleResponse);
}
function deleteInactiveTaem(req) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/team/delete/${req.id}`, requestOptions).then(
    handleResponse
  );
}
function Team_cerete(PointerParams) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(PointerParams),
  };
  console.log(PointerParams, 'PointerParams');
  return fetch(`${baseURL}/team/create`, requestOptions).then(handleResponse);
}
function getTeamById(req) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/team/get-team-by-id/${req}`, requestOptions).then(
    handleResponse
  );
}
function updateTeam(req, id) {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(req),
  };
  return fetch(`${baseURL}/team/update/${id}`, requestOptions).then(
    handleResponse
  );
}
