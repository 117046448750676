import { Box } from "@mui/material"
import styles from './TebalNoData.module.scss';

export const TebalNoData = ({text}:any) => {

    return (
        <div className={styles.noDataClass}>
            <div>
            <div className={styles.noDataimg}>
                <img src="/uil_transaction.svg" />
            </div>
            <p style={{paddingTop:"20px"}}>{text}</p>
            </div>
        </div>
    )
}