export const entityConstants = {
    ADDTOENTITY_REQUEST: 'ENTITY_ADDTOENTITY_REQUEST',
    ADDTOENTITY_SUCCESS: 'ENTITY_ADDTOENTITY_SUCCESS',
    ADDTOENTITY_FAILURE: 'ENTITY_ADDTOENTITY_FAILURE',

    ADDTOENTITY_V2_REQUEST: 'ENTITY_ADDTOENTITY_V2_REQUEST',
    ADDTOENTITY_V2_SUCCESS: 'ENTITY_ADDTOENTITY_V2_SUCCESS',
    ADDTOENTITY_V2_FAILURE: 'ENTITY_ADDTOENTITY_V2_FAILURE',

    GETENTITYITEMS_REQUEST: 'ENTITY_GETENTITYITEMS_REQUEST',
    GETENTITYITEMS_SUCCESS: 'ENTITY_GETENTITYITEMS_SUCCESS',
    GETENTITYITEMS_FROM_STORE: 'ENTITY_GETENTITYITEMS_FROM_STORE',
    GETENTITYITEMS_FAILURE: 'ENTITY_GETENTITYITEMS_FAILURE',

    BILLLIST_REQUEST: 'BILLLIST_REQUEST',
    BILLLIST_SUCCESS: 'BILLLIST_SUCCESS',
    BILLLIST_FROM_STORE: 'BILLLIST_FROM_STORE',
    BILLLIST_FAILURE: 'BILLLIST_FAILURE',

    GET_CURRENT_STEP_REQUEST: "GET_CURRENT_STEP_REQUEST",
    GET_CURRENT_STEP_SUCCESS: "GET_CURRENT_STEP_SUCCESS",
    GET_CURRENT_STEP_FAILURE: "GET_CURRENT_STEP_FAILURE",

    SAVE_BUSINESS_DETAILS_REQUEST: "SAVE_BUSINESS_DETAILS_REQUEST",
    SAVE_BUSINESS_DETAILS_SUCCESS: "SAVE_BUSINESS_DETAILS_SUCCESS",
    SAVE_BUSINESS_DETAILS_FAILURE: "SAVE_BUSINESS_DETAILS_FAILURE",

    GET_DETAILS_FOR_NEW_API_REQUEST: "GET_DETAILS_FOR_NEW_API_REQUEST",
    GET_DETAILS_FOR_NEW_API_SUCCESS: "GET_DETAILS_FOR_NEW_API_SUCCESS",
    GET_DETAILS_FOR_NEW_API_FAILURE: "GET_DETAILS_FOR_NEW_API_FAILURE",

    SET_CURRENT_STEP_REQUEST: "SET_CURRENT_STEP_REQUEST",
    SET_CURRENT_STEP_SUCCESS: "SET_CURRENT_STEP_SUCCESS",
    SET_CURRENT_STEP_FAILURE: "SET_CURRENT_STEP_FAILURE",

    GET_ENTITY_ACTIVITY_SUCCESS: 'GET_ENTITY_ACTIVITY_SUCCESS',
    UPDATEENTITY_REQUEST: 'ENTITY_UPDATEENTITY_REQUEST',
    UPDATEENTITY_SUCCESS: 'ENTITY_UPDATEENTITY_SUCCESS',
    UPDATEENTITY_FAILURE: 'ENTITY_UPDATEENTITY_FAILURE',
    GETENTITY_REQUEST: 'ENTITY_GETENTITY_REQUEST',
    GETENTITY_SUCCESS: 'ENTITY_GETENTITY_SUCCESS',
    GETENTITY_FAILURE: 'ENTITY_GETENTITY_FAILURE',

    BUSINESS_DETAILS_REQUEST: 'BUSINESS_DETAILS_REQUEST',
    BUSINESS_DETAILS_SUCCESS: 'BUSINESS_DETAILS_SUCCESS',
    BUSINESS_DETAILS_FAILURE: 'BUSINESS_DETAILS_FAILURE',

    UPDET_DATA_REQUEST: "UPDET_DATA_REQUEST",
    UPDET_DATA_SUCCESS: "UPDET_DATA_SUCCESS",
    UPDET_DATA_FAILURE: "UPDET_DATA_FAILURE",

    SAVE_ENTITY_REQUEST:"SAVE_ENTITY_REQUEST",
    SAVE_ENTITY_SUCCESS:"SAVE_ENTITY_SUCCESS",
    SAVE_ENTITY_FAILURE:"SAVE_ENTITY_FAILURE",
}