import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import React, { useState } from 'react';
import { Button, Table } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Box, IconButton, Typography } from '@mui/material';
import styles from './tebal.module.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { paymentJourneyActions } from "../../../../../../../../_actions/paymentJourney.actions";
import ShareIcon from '@mui/icons-material/Share';
import { Tooltip } from 'react-tooltip';
import { FacebookShareButton, WhatsappShareButton, EmailShareButton } from 'react-share';
import { TebalNoData } from '../../../../../Skeleton/TebalNoData';
const headCells = [
    {
        id: 'transaction_date',
        numeric: false,
        disablePadding: true,
        label: 'Date',
    },
    {
        id: 'reference',
        numeric: false,
        disablePadding: true,
        label: 'Payment reference',
    },
    {
        id: 'Type',
        numeric: false,
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'beneficiary',
        numeric: true,
        disablePadding: false,
        label: 'Payee',
    },
    {
        id: 'category',
        numeric: true,
        disablePadding: false,
        label: 'Category',
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description ',
    },
    {
        id: 'transaction_amount',
        numeric: false,
        disablePadding: false,
        label: 'Transaction amount',
    },
    {
        id: 'transaction_status',
        numeric: false,
        disablePadding: false,
        label: 'Transaction Status',
    },
    {
        id: 'balance',
        numeric: false,
        disablePadding: false,
        label: 'Closing balance',
    },
    {
        id: 'more_info',
        numeric: false,
        disablePadding: false,
        label: 'More Info',
    },
];
function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } =
        props;
    const createSortHandler =
        (property) => (event) => {
            //onRequestSort(event, property);
        };
    var newHeadcells = headCells

    return (
        <thead>
            <tr>
                {/* <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell> */}
                {newHeadcells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className="th"
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </tr>
        </thead>
    );
}

const Tebal = (props) => {
    const rows = props.data ? props.data : [];
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('transaction_date');
    const [selected, setSelected] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElWhatsApp, setAnchorElWhatsApp] = React.useState(null);
    const [ideditdata, setIdeditdata] = React.useState('')
    const [short_code, setShort_code] = React.useState("")
    const [open_copy, setOpen_copy] = React.useState(false);
    const [shareLink, setSreeaLink] = useState('')
    const dispatch = useDispatch();
    console.log(rows, "rowsrowsrows");
    const open = Boolean(anchorEl);
    const openWhatsApp = Boolean(anchorElWhatsApp)
    const navigate = useNavigate();

    const handleClick_datat = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClose_twoo = () => {
        setAnchorElWhatsApp(null);
    };
    const handleClick = (event) => {
        setAnchorElWhatsApp(event.currentTarget);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleTooltipClose = () => {
        setOpen_copy(false);
    };

    const handleTooltipOpen = () => {
        setOpen_copy(true);
        setTimeout(() => {
            setOpen_copy(false)
        }, 3000)
    };
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    const deleteData = () => {
        dispatch(paymentJourneyActions.PaymentLinkApiDeleteActive(ideditdata));
    }
    return (
        <>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    className='menuitem'
                    onClick={() => {
                        navigate("/settings/CeretePaymentLink?short_code=" + short_code, { replace: true, state: { short_code: short_code } });
                    }}
                >Edit</MenuItem>
                <MenuItem onClick={() => {

                    // handleClickOpendelog()
                    // handleShow()
                    deleteData()
                    handleClose()
                }} className='menuitem' style={{ color: "#F87161" }}>Delete
                </MenuItem>
            </Menu>
            <Menu
                id="basic-menu"
                anchorEl={anchorElWhatsApp}
                open={openWhatsApp}
                onClose={handleClose_twoo}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{ "& .MuiMenu-list": { padding: "0px" } }}
            >
                <div style={{ padding: "5px" }}>
                    <div style={{ height: "27px" }}>
                        <WhatsappShareButton
                            url={shareLink}
                            onClick={handleClose_twoo}
                        >
                            Send via WhatsApp
                        </WhatsappShareButton>
                    </div>
                    <div style={{ height: "27px" }}>
                        <EmailShareButton
                            // onClick={handleClose_twoo}
                            url={shareLink}
                            body={`${process.env.REACT_APP_API_URL}/get-transaction-pdf-link?hash=${shareLink}${'\n'}`}
                        >
                            Send via Email
                        </EmailShareButton>

                    </div>
                </div>
            </Menu>
            <TableContainer>
                <Table
                    className={styles.tebal_class_contean}
                >
                    <thead>
                        <tr>
                            <TableCell sx={{ width: "30%" }} className={styles.LinksTableCell}>
                            Payment Link
                            </TableCell>
                            <TableCell className={styles.LinksTableCell}>
                            Status
                            </TableCell>
                            {/* <TableCell className={styles.LinksTableCell}>
                                Created at
                            </TableCell> */}
                            <TableCell className={styles.LinksTableCell}>

                            Last Updated
                            </TableCell>
                            <TableCell className={styles.LinksTableCell} style={{textAlign:"center"}}>
                            Product Description
                            </TableCell>
                            <TableCell className={styles.LinksTableCell}>

                            Link Amount
                            </TableCell>
                            <TableCell className={styles.LinksTableCell}>
                            Payments Collected
                            </TableCell>
                            
                            <TableCell className={styles.LinksTableCell}>
                            </TableCell>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            rows
                                .map((element, index) => {
                                    var TAmount = element.transaction_amount;


                                    return (
                                        <TableRow key={element.id} >
                                            <TableCell className={styles.TableCell_roww}>
                                                <Box className={styles.Typography_Box}>
                                                    <Typography style={{ cursor: "pointer" }} onClick={() => {
                                                        navigate("/settings/Detels_payment_Link?id=" +
                                                            element.id, { replace: true, state: { id: element.id } });
                                                    }}>
                                                        {element.link}
                                                    </Typography>
                                                    <div>
                                                        <Button onClick={(e) => {
                                                            setSreeaLink(element.link)
                                                            handleClick(e)
                                                        }} style={{ marginRight: "10px" }} className={styles.button_ContentCopyIcon}>
                                                            <ShareIcon />
                                                        </Button>
                                                        <CopyToClipboard text={element.link}>
                                                            {/* <ClickAwayListener onClickAway={handleTooltipClose}> */}
                                                            {/* <Tooltip
                                                                key={element.id}
                                                                    PopperProps={{
                                                                        disablePortal: true,
                                                                    }}
                                                                    onClose={handleTooltipClose}
                                                                    open={open_copy}
                                                                    disableFocusListener
                                                                    disableHoverListener
                                                                    disableTouchListener
                                                                    title="Add"
                                                                > */}
                                                            <Button data-tooltip-id="my-tooltip-click" className={styles.button_ContentCopyIcon}>
                                                                <ContentCopyIcon />
                                                            </Button>
                                                            {/* </Tooltip> */}
                                                            {/* </ClickAwayListener> */}
                                                        </CopyToClipboard>
                                                    </div>
                                                </Box>
                                            </TableCell>
                                            <TableCell className={styles.TableCell_roww}>
                                                <Typography className={element.status == "inactive" ? styles.inactiveTypography : styles.TypographyStatus}>
                                                    {element.status=="active"?"Active":element.status=="inactive"?"Inactive":""}
                                                </Typography>
                                            </TableCell>
                                            {/* <TableCell className={styles.TableCell_roww}>
                                                <Typography className={styles.TypographyCreated_at}>
                                                    {moment(element.created_at).format("DD-MMM-YYYY")}
                                                </Typography>
                                            </TableCell> */}
                                            <TableCell className={styles.TableCell_roww}>
                                                <Typography className={styles.TypographyUpdated_at}>
                                                    {moment(element.updated_at).format("DD-MMM-YYYY")}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={styles.TableCell_roww}>
                                                <Typography style={{textAlign:"center"}} className={styles.TypographyUpdated_at}>
                                                   {!!element.description?element.description:"-"}
                                                </Typography>
                                            </TableCell>
                                       
                                            <TableCell className={styles.TableCell_roww}>
                                            {element.amount==0?
                                                <Typography className={styles.TypographyUpdated_at}>
                                                N/A
                                                </Typography>
                                                :<Typography className={styles.TypographyUpdated_at}>

                                                {element.currency}{" "}{element.amount}
                                                </Typography>}
                                            </TableCell>
                                            <TableCell className={styles.TableCell_roww}>
                                                <Typography className={styles.TypographyUpdated_at}>
                                                {" "}{element.total_transactions}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ width: "5%" }} className={styles.TableCell_roww}>
                                                <IconButton disabled={element.status == "inactive" ? true : false} onClick={(e) => {
                                                    handleClick_datat(e)
                                                    setIdeditdata(element.id)
                                                    setShort_code(element.short_code)
                                                }}>
                                                    <MoreVertIcon />
                                                </IconButton>

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {rows?.length === 0 && (
                  <TableCell colSpan={10}>
                    <TebalNoData text={'No  Payment Links activity'}  />
                    </TableCell>
                )}
                    </tbody>
                </Table>
            </TableContainer>
            <Tooltip
                id="my-tooltip-click"
                content="link has been copied"
                events={['click']}
            />
        </>
    )
}
export default Tebal;
const Sreea = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path d="M9.33464 8.48193C8.87019 8.48193 8.45464 8.66265 8.13686 8.94578L3.77964 6.44578C3.81019 6.30723 3.83464 6.16867 3.83464 6.0241C3.83464 5.87952 3.81019 5.74096 3.77964 5.60241L8.08797 3.12651C8.41797 3.42771 8.85186 3.61446 9.33464 3.61446C10.3491 3.61446 11.168 2.80723 11.168 1.80723C11.168 0.807229 10.3491 0 9.33464 0C8.32019 0 7.5013 0.807229 7.5013 1.80723C7.5013 1.95181 7.52575 2.09036 7.5563 2.22892L3.24797 4.70482C2.91797 4.40361 2.48408 4.21687 2.0013 4.21687C0.986858 4.21687 0.167969 5.0241 0.167969 6.0241C0.167969 7.0241 0.986858 7.83133 2.0013 7.83133C2.48408 7.83133 2.91797 7.64458 3.24797 7.34337L7.59908 9.8494C7.56852 9.9759 7.55019 10.1084 7.55019 10.241C7.55019 11.2108 8.35075 12 9.33464 12C10.3185 12 11.1191 11.2108 11.1191 10.241C11.1191 9.27108 10.3185 8.48193 9.33464 8.48193ZM9.33464 1.20482C9.67075 1.20482 9.94575 1.4759 9.94575 1.80723C9.94575 2.13855 9.67075 2.40964 9.33464 2.40964C8.99852 2.40964 8.72352 2.13855 8.72352 1.80723C8.72352 1.4759 8.99852 1.20482 9.33464 1.20482ZM2.0013 6.62651C1.66519 6.62651 1.39019 6.35542 1.39019 6.0241C1.39019 5.69277 1.66519 5.42169 2.0013 5.42169C2.33741 5.42169 2.61241 5.69277 2.61241 6.0241C2.61241 6.35542 2.33741 6.62651 2.0013 6.62651ZM9.33464 10.8554C8.99852 10.8554 8.72352 10.5843 8.72352 10.253C8.72352 9.92169 8.99852 9.6506 9.33464 9.6506C9.67075 9.6506 9.94575 9.92169 9.94575 10.253C9.94575 10.5843 9.67075 10.8554 9.33464 10.8554Z" fill="white" />
    </svg>
)