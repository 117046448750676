import React, { useEffect, useState } from 'react'
import styles from '../styles.module.scss';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { userActions } from '../../../../_actions'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import '../../../auth/Auth.css';
import showPwdImg from '../ShowPassword/show-password.svg';
import hidePwdImg from '../ShowPassword/hide-password.svg';
import { LoginHeader } from '../../../auth/LoginHeader'
import { getUser, isUserAuthenticated } from "../../../../_helpers";
import { entityActions } from "../../../../_actions/entity.actions";
// import {store} from "../../../../Store/store";
import { getEntityActivitiesReducer, selectAllData } from "../../../../_reducers/entity.reducer";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import RegisterLayout from '../../../auth/Layout/RegisterLayout';
import EntityLayout from '../../../auth/Layout/entityLayout';
import { Modal } from 'react-bootstrap';


const Entity = () => {
    const [entity, setEntity] = useState<EntityRequest>({
        business_name: '',
        // registration_number: '',
        company_type: '',
        business_activity: '',
        country: '',
        // state: '',
        // city: "",
        // zip_code: "",
        street_address: '',
    })
    console.log(entity, "entityentity");

    const navigate = useNavigate();
    const user = getUser()?.data ?? null

    // console.log('user', user)

    useEffect(() => {
        if (!user && !isUserAuthenticated()) {
            navigate('/login');
        }
    }, [])

    const [submitted, setSubmitted] = useState(false)
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [entityType, setEntityType] = useState<any>();
    const [entityActivity, setEntityActivity] = useState<any>();
    const [isInitialTypeRender, setIsInitialTypeRender] = useState(true);
    const [render, setRender] = useState(true);
    const [countryError, setCountryError] = useState(false);
    const [regionError, setRegionError] = useState(false);
    const [registerRender, setregisterRender] = useState(true);
    const [regfailerRender, setregfailerRender] = useState(true);
    const [registered, setregistered] = useState<any>();
    const [registerReqeust, setregisterReqeust] = useState(true);
    const [isInitialActivityRender, setIsInitialActivityRender] = useState(true);

    const data = useSelector((state) => selectAllData(state))

    const [requestRender, setrequestRender] = useState(true);
    const [responseRender, setresponseRender] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [bodyData, setBodyData] = useState<any>()
    const [country, setCountry] = useState<any>();
    const [region, setRegion] = useState<any>();
    const APIData = useSelector((state) =>
        selectAllData(state)
    );
    const onClose = () => {
        setLoading(false)
    }
    // useEffect(() => {
    //     if (requestRender && APIData?.ADDTOENTITY_REQUEST == true) {
    //         setLoading(APIData.ADDTOENTITY_REQUEST);
    //         setresponseRender(true);
    //         setrequestRender(false);

    //     }
    // }, [APIData, requestRender]);

    // useEffect(() => {
    //     if (responseRender && APIData?.ADDTOENTITY_REQUEST == false) {
    //         setLoading(false);
    //         setrequestRender(true);
    //         setresponseRender(false);
    //     }
    // }, [APIData, requestRender]);


    const dispatch = useDispatch()

    useEffect(() => {
        if (isUserAuthenticated() && render) {
            setRender(false)
            dispatch(entityActions.getEntityTypes() as any)
            dispatch(entityActions.getEntityActivities() as any)
        }
    }, [render, isUserAuthenticated]);

    useEffect(() => {
        if (isInitialTypeRender && data?.GETENTITYITEMS_SUCCESS !== undefined) {
            setIsInitialTypeRender(false);
            setEntityType(data.GETENTITYITEMS_SUCCESS);

        }
    }, [data, isInitialTypeRender]);

    useEffect(() => {
        if (isInitialActivityRender && data?.GET_ENTITY_ACTIVITY_SUCCESS !== undefined) {
            setIsInitialActivityRender(false);
            setEntityActivity(data.GET_ENTITY_ACTIVITY_SUCCESS);
        }
    }, [data, isInitialActivityRender]);


    function resetResquest() {
        setregisterRender(true);
        setregfailerRender(true);
    }
  
    console.log(APIData?.ADDTOENTITY_SUCCESS,APIData?.ADDTOENTITY_FAILURE,loading, "sdfzsffszfff");

    // useEffect(() => {
    //     if (registerRender && APIData?.ADDTOENTITY_SUCCESS !== undefined) {
    //         setregisterRender(false);
    //         setregistered(APIData.ADDTOENTITY_SUCCESS);
    //         handleSubmitDevapi()
    //         // setLoading(true);
    //         // setLoading(false);
    //         // setregisterReqeust(true);
    //     }
    // }, [APIData?.ADDTOENTITY_SUCCESS, registerRender]);
    useEffect(() => {
        if (regfailerRender && APIData?.ADDTOENTITY_FAILURE !== undefined) {
            setregfailerRender(false);
           
            // setregisterReqeust(true);
        }
    }, [APIData?.ADDTOENTITY_FAILURE,regfailerRender]);
    // useEffect(() => {
    //     if (registerReqeust && APIData?.ADDTOENTITY_REQUEST !== undefined) {
    //         console.log('loading', registerReqeust);
    //         resetResquest();
    //         setregisterReqeust(false);
    //         // setLoading(APIData.ADDTOENTITY_FAILURE);
    //     }
    // }, [registerReqeust]);
    // console.log("enitities =>",entityType, entityActivity);

    //const registering = useSelector(state => state.registration.registering)

    const validationSchema = Yup.object().shape({
        business_name: Yup.string()
            .required('Please enter the business name.'),
        // registration_number: Yup.string()
        //     .required('Enter a Registration number in the required field'),
        company_type: Yup.string()
            .required('Please select the business type.'),
        business_activity: Yup.string()
            .required('Please specify the Business activity.'),
        country: Yup.string()
            .required('Please select the country where the business is located.'),
        // state: Yup.string()
        //     .required('Please enter the state where the business is located.'),
        // tax_identification_number: Yup.string()
        //     .required('Enter a Tax identification number in the required field'),
        street_address: Yup.string()
            .required('Please enter the street address.'),
        // city: Yup.string()
        //     .required('Please select the city where the business is located.'),
        // zip_code: Yup.string()
        //     .required('Please enter the postal address.'),

    })
    const handleSubmitDevapi = (bodyData:any) => {
        setSubmitted(true);
        if (bodyData.business_name && bodyData.company_type && bodyData.business_activity && !!country) {
            bodyData.country = country
            bodyData.state = region
            var APIURL = process.env.REACT_APP_STAGING_API_URL
            setLoading(true);
            dispatch(entityActions.createEntity(bodyData, APIURL,setLoading,'') as any)
        }
    }
    const handleSubmit = (e: any) => {
        setSubmitted(true);
        console.log(e.business_name && e.company_type && e.business_activity && !!country, e, country, "business_namebusiness_name");

        if (e.business_name && e.company_type && e.business_activity && !!country) {
            e.country = country
            e.state = region
            var APIURL = process.env.REACT_APP_API_URL
            resetResquest()
            console.log(e, 'Submit')
            // setBodyData(e)
            console.log("szdzdfszfffzfff", APIURL);
            setLoading(true);
            dispatch(entityActions.createEntity(e, APIURL,setLoading,'/dashboard') as any)
            handleSubmitDevapi(e)
        }
    }
    return (
        <>
            {
                (loading == true) && (
                    <Modal
                        show={loading}
                        onHide={onClose}
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby='ModalHeader'
                        className="topup_modal"
                        centered
                        size="lg"
                    // id={styles.maiana_contwean_abh}
                    >
                        <Modal.Body>
                            <>

                                <div className="div_pading_data">
                                    <img src="/logo 3.svg" />
                                    <p className="des_data">
                                    Setting up your account. This may take a few moments. Do not refresh or close this window  until the process is complete.
                                    </p>
                                    {/* <Loader_icon /> */}
                                    <img
                                        width={75}
                                        height={75}
                                        src="/Niobi Loading Animation-Cross.gif"
                                    />
                                </div>
                            </>
                        </Modal.Body>
                    </Modal>
                )
            }
            <EntityLayout>

                <div className="formBx_tow" style={{ justifyContent: "center", height: "100%" }}>
                    <div className='register-business'>
                        <span><h2>Register your business</h2></span>
                    </div>
                    <Formik initialValues={entity} validationSchema={validationSchema} onSubmit={handleSubmit}>
                        {({ errors, touched, isSubmitting, handleChange, values }) => (
                            <Form className='form-input'>
                                <div className='inputBx'>
                                    <span>Business Name</span>
                                    <Field
                                        name="business_name"
                                        type="text"

                                        placeholder="Business Name"
                                        className={'form-text form-control' + (errors.business_name && touched.business_name ? ' is-invalid' : '')} />
                                    <ErrorMessage name="business_name" component="div" className="invalid-feedback red" />
                                </div>
                                {/* <div className='inputBx'>
                                <span>Registration Number</span>
                                <Field  name="registration_number" type="text" placeholder="Registration Number" className={'form-text form-control' + (errors.registration_number && touched.registration_number ? ' is-invalid' : '')} />
                                <ErrorMessage name="registration_number" component="div" className="invalid-feedback red" />
                            </div> */}

                                <div className='inputBx'>
                                    <span>Business Type</span>
                                    <Field name="company_type" id="CountryDropdown_class" as="select" className={'form-text form-control select-box' + (errors.company_type && touched.company_type ? ' is-invalid' : '')} >
                                        <option value="">--Select--</option>
                                        {Array.isArray(entityType)
                                            ? entityType?.map((element: any) => {
                                                return <option key={element.id} value={element.id}>{element.name}</option>
                                            })
                                            : "data not found"}
                                    </Field>
                                    <ErrorMessage name="company_type" component="div" className="invalid-feedback red" />
                                </div>
                                <div className='inputBx'>
                                    <span>Business Activity</span>
                                    <Field name="business_activity" id="CountryDropdown_class" as="select" className={'form-text form-control select-box' + (errors.business_activity && touched.business_activity ? ' is-invalid' : '')} >
                                        <option value="">--Select--</option>
                                        {Array.isArray(entityActivity)
                                            ? entityActivity?.map((element: any) => {
                                                return <option key={element.id} value={element.id}>{element.name}</option>
                                            })
                                            : "data not found"}
                                    </Field>
                                    <ErrorMessage name="business_activity" component="div" className="invalid-feedback red" />
                                </div>


                                {/* <div className='inputBx'>
                                <span>Tax Identification Number</span>
                                <Field  name="tax_identification_number" type="text" placeholder="Tax Identification Number" className={'form-text form-control' + (errors.tax_identification_number && touched.tax_identification_number ? ' is-invalid' : '')} />
                                <ErrorMessage name="tax_identification_number" component="div" className="invalid-feedback red" />
                            </div> */}

                                <div className='inputBx'>
                                    <span>Street Address</span>
                                    <Field name="street_address" type="text" placeholder="Street Address" className={'form-text form-control' + (errors.street_address && touched.street_address ? ' is-invalid' : '')} />
                                    <ErrorMessage name="street_address" component="div" className="invalid-feedback red" />
                                </div>

                                {/* 
                                <div className='inputBx'>
                                    <span>City</span>
                                    <Field name="city" type="text" placeholder="City" className={'form-text form-control' + (errors.city && touched.city ? ' is-invalid' : '')} />
                                    <ErrorMessage name="city" component="div" className="invalid-feedback red" />
                                </div> */}

                                {/* <div className='inputBx'>
                                    <span>Postal Address</span>
                                    <Field name="zip_code" type="text" placeholder="Postal Address" className={'form-text form-control' + (errors.zip_code && touched.zip_code ? ' is-invalid' : '')} />
                                    <ErrorMessage name="zip_code" component="div" className="invalid-feedback red" />
                                </div> */}

                                <div className='inputBx'>
                                    <span>Country</span>
                                    <CountryDropdown
                                        id="CountryDropdown_class"
                                        value={country}
                                        onChange={
                                            (e, event) => {
                                                setCountryError(!!e ? false : true)
                                                setCountry(e);
                                                handleChange(event);
                                            }}
                                        name="country"
                                        classes={'form-text form-control' + (errors.country && touched.country ? ' is-invalid' : '')}
                                    />
                                    <ErrorMessage name="country" component="div" className="invalid-feedback red" />
                                </div>

                                {/* <div className='inputBx'>
                                    <span>County    </span>
                                    <RegionDropdown
                                        country={country}
                                        value={region}
                                        name="state"
                                        onChange={(val, e) => {
                                            setRegion(val);
                                            handleChange(e);
                                        }}
                                        classes={'form-text form-control' + (errors.state && touched.state ? ' is-invalid' : '')}
                                    />
                                    <ErrorMessage name="state" component="div" className="invalid-feedback red" />

                                </div> */}
                                <div className='inputBx'>
                                    <button type="submit"
                                        //disabled={isSubmitting} 
                                        className="form-button">
                                        {loading == true && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        <span>Register</span>
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </EntityLayout>
        </>
    )
}
export default Entity;
interface EntityRequest {
    business_name: string;
    // registration_number: string;
    company_type: string;
    business_activity: string;
    country: string;
    // state: string;
    street_address: string;
    // tax_identification_number: string;
    // city: string;
    // zip_code: string;
}