import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import '../../auth/Auth.css';
import {
  Col,
  Form,
  Button,
  InputGroup,
  Nav,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import { getUser, isUserAuthenticated } from '../../../_helpers';
import { entityActions } from '../../../_actions/entity.actions';
import {
  getEntityActivitiesReducer,
  selectAllData,
} from '../../../_reducers/entity.reducer';
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from 'react-country-region-selector';
import { Modal } from 'react-bootstrap';
import styles from './entity.module.scss';
import { Box, Checkbox, Grid, Select, Typography, Radio } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { registerSelector } from '../../../_reducers/registration.reducer';
import { choiceActions } from '../../../_actions';

const NewEntity = ({ setSetpNumberAuth, path, api, updateStep }: any) => {
  // const [entity, setEntity] = useState<EntityRequest>({
  //   business_name: "",
  //   company_type: "",
  //   business_activity: "",
  //   country: "",
  //   street_address: "",
  // });
  const navigate = useNavigate();
  const user = getUser()?.data ?? null;

  //   useEffect(() => {
  //     if (!user && !isUserAuthenticated()) {
  //       navigate("/login");
  //     }
  //   }, []);

  const [submitted, setSubmitted] = useState(false);
  const [entityType, setEntityType] = useState<any>();
  const [entityActivity, setEntityActivity] = useState<any>();
  const [isInitialTypeRender, setIsInitialTypeRender] = useState(true);
  const [render, setRender] = useState(true);
  const [countryError, setCountryError] = useState(false);
  const [isInitialActivityRender, setIsInitialActivityRender] = useState(true);
  const data = useSelector((state) => selectAllData(state));
  const [requestRender, setrequestRender] = useState(true);
  const [responseRender, setresponseRender] = useState(false);
  const [responseRender1, setresponseRender1] = useState(true);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState<any>();
  const [region, setRegion] = useState<any>();
  const [qeuid, setQeuId] = useState(false);
  const [userId, setUserId] = useState<any>(0);
  const [dataapi, setDataAPI] = useState<any>('');
  const [datalast, setDatalast] = useState<any>('');
  const [LastQes, setLastQes] = useState<any>(0);
  const [businessName, setbusinessName] = useState('');
  const [offerings, setOfferings] = useState('');
  const [BusinessActivity, setBusinessActivity] = useState("")
  const [businessDetails, setBusinessDetails] = useState<any>('');
  const [rendarData, setRebderData] = useState(false)
  const [transactionActivity, setTransactionActivity] = useState([
    {
      name: 'USD 1 - USD 10,000',
      value: false,
      id: 1,
      APIdata: '1 to 10000 USD',
    },
    {
      name: 'USD 10,001 - USD 100,000',
      value: false,
      id: 2,
      APIdata: '10001 to 100000 USD',
    },
    {
      name: 'USD 100,001 - USD 500,000',
      value: false,
      id: 3,
      APIdata: '100001 to 500000 USD',
    },
    {
      name: 'USD 500,001 - USD 1,000,000',
      value: false,
      id: 4,
      APIdata: '500001 to 1000000 USD',
    },

    {
      name: 'Above USD 1,000,000',
      value: false,
      id: 5,
      APIdata: '1000000 USD',
    },
  ]);
  const [payloadpayin, setPayloadpayin] = useState("")
  const [payloadpayout, setPayloadpayout] = useState("")
  const [submitedButton, setsubmitedButton] = useState(false);
  const [rendar, setRenderdata] = React.useState(true);
  const [ListQesan, setListQesan] = useState([
    {
      name: 'Manage business banking and payments',
      id: 1,
      value: false,
    },
    {
      name: 'Use Niobi’s suite of payment APIs',
      id: 2,
      value: true,
    },
    {
      name: 'Track and manage business finance operations',
      id: 3,
      value: false,
    },
  ]);
  const [rendarapi, setRenderapi] = React.useState(true);
  const businessDetailsReducer = useSelector(
    (state: any) => state.getBusinessDetailsReducer.data
  );
  const APIData = useSelector((state) => selectAllData(state));
  const registrationData = useSelector((state) => registerSelector(state));
  const localData = localStorage.getItem('user');
  const parseData = localData ? JSON.parse(localData) : '';
  console.log(parseData, 'registrationData__registrationData', businessDetails);
  useEffect(() => {
    if (APIData && APIData.GET_DETAILS_FOR_NEW_API_SUCCESS && responseRender1) {
      setBusinessDetails(APIData?.GET_DETAILS_FOR_NEW_API_SUCCESS?.data);
      setresponseRender1(false);
    }
  }, [APIData.GET_DETAILS_FOR_NEW_API_SUCCESS, responseRender1]);
  useEffect(() => {
    if (requestRender && APIData?.ADDTOENTITY_FAILURE == true) {
      setLoading(false);
      setrequestRender(false);
    }
  }, [APIData, requestRender]);

  useEffect(() => {
    if (responseRender && APIData?.ADDTOENTITY_V2_SUCCESS == false) {
      setLoading(false);
      setresponseRender(false);
    }
  }, [APIData, requestRender]);

  const dispatch = useDispatch();
  const onClose = () => {
    setLoading(false);
  };
  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(entityActions.getEntityTypes() as any);
      dispatch(entityActions.getEntityActivities() as any);
    }
  }, [render]);

  useEffect(() => {
    if (parseData?.data?.user_id) {
      const user_id = parseData?.data?.user_id;
      dispatch(entityActions.getDetailsForNewAPIAction(user_id) as any);
      setresponseRender1(true);
    }
  }, [parseData?.data?.user_id]);

  useEffect(() => {
    if (isInitialTypeRender && data?.GETENTITYITEMS_SUCCESS !== undefined) {
      setIsInitialTypeRender(false);
      setEntityType(data.GETENTITYITEMS_SUCCESS);
    }
  }, [data, isInitialTypeRender]);

  useEffect(() => {
    if (
      isInitialActivityRender &&
      data?.GET_ENTITY_ACTIVITY_SUCCESS !== undefined
    ) {
      setIsInitialActivityRender(false);
      setEntityActivity(data.GET_ENTITY_ACTIVITY_SUCCESS);
    }
  }, [data, isInitialActivityRender]);
  // const validationSchema = Yup.object().shape({
  //   business_name: Yup.string().required("Please enter the business name."),
  //   company_type: Yup.string().required("Please select the business type."),
  //   business_activity: Yup.string().required(
  //     "Please specify the Business activity."
  //   ),
  //   country: Yup.string().required(
  //     "Please select the country where the business is located."
  //   ),
  //   street_address: Yup.string().required("Please enter the street address."),
  // });
  const navigateAfter = () => {
    navigate(path)
  }
  const handleSubmit = () => {
    setSubmitted(true);
    const ListFind = ListQesan.find((data) => data.value == true);
    // const findAmount = directors.find((data: any) => data.Amount == '')
    console.log(ListFind, 'ListFind');
    if (ListFind == undefined) {
      setQeuId(true);
    } else {
      setQeuId(false);
    }
    // updateStep(6)
    // var NewArrry = [];
    // for (let index = 0; index < ListQesan.length; index++) {
    //   const element = ListQesan[index];
    //   if (element.value == true) {
    //     var name = `Use ${element.id}`;
    //     NewArrry.push(name);
    //   }
    // }
    // console.log(NewArrry, "NewArrry");

    // const afterCall = (path_: string) => {
    //   updateStep(5)

    //   setTimeout(() => {
    //     navigate(path_)
    //   }, 1000)
    // }

    var NewArrry = [];
    var nameArray = []
    for (let index = 0; index < ListQesan.length; index++) {
      const element = ListQesan[index];
      if (element.value == true) {
        var name = `Use ${element.id}`;
        var nameData = element.name
        NewArrry.push(name);
        nameArray.push(nameData)
      }
    }

    if (!!businessName && !!ListFind && !!offerings && !!userId && !!LastQes) {
      var body = {
        business_name: businessDetails?.businessName,
        // company_type: "1",
        business_activity: businessName,
        street_address: businessDetails?.businessAddress,
        country: businessDetails?.country,
        account_usage: NewArrry,
        product_offering: offerings,
        monthly_payins: dataapi,
        monthly_payouts: datalast,
        entity_id: user?.entity?.id,
      };
      var saveBody = {
        "business_activity": businessName,
        business_activity_name: BusinessActivity,
        "account_usage": nameArray,
        "product_offering": offerings,
        "pay_ins": payloadpayin,
        "pay_outs": payloadpayout
      }
      const Newigesan = () => {
        updateStep(4, true, navigate(path));
        navigate(path);
        setSetpNumberAuth(4);
      };
      setLoading(true);
      setresponseRender(true);
      setrequestRender(true);
      const Entitysave=()=>{
        dispatch(entityActions.createEntityv2(body, path, api, Newigesan) as any)
      }
      dispatch(
        entityActions.saveBusinessDetailsAction(saveBody,Entitysave,false) as any
      );
      
    }
  };
  React.useEffect(() => {
    if (rendar) {
      var body = {
        user_id: user?.user_id,
      };
      // dispatch(entityActions.getBusinessDetails(body) as any);
      dispatch(choiceActions.getBusinessDetails(user?.user_id) as any);
      setRenderdata(false);
      setTimeout(() => {
        setRebderData(true)

      }, 3000)
    }
  }, [rendar]);
  useEffect(() => {
    if (!!businessDetailsReducer && !!user?.entity?.id && rendarData) {
      console.log(businessDetailsReducer, "businessDetailsReducer");
      if (!!businessDetailsReducer?.business_activity) {
        setbusinessName(businessDetailsReducer?.business_activity)
      }
      // var newArray = [];
      if (!!businessDetailsReducer?.account_usage) {
        var newArray = [];
        for (
          let index = 0;
          index < ListQesan.length;
          index++
        ) {
          const element = ListQesan[index];
          var finddata = businessDetailsReducer?.account_usage.find((data: any) => data == element.name)
          if (finddata == element.name) {
            console.log(element, "newArraynewArray123");
            element.value = true
            newArray.push(element)
          } else {
            element.value = false
            newArray.push(element)
          }

        }
      }
      if (!!businessDetailsReducer?.product_offering) {
        setOfferings(businessDetailsReducer?.product_offering)
      }
      if (!!businessDetailsReducer?.pay_ins) {
        var findtransactionActivity = transactionActivity.find((data: any) => data?.name ==businessDetailsReducer?.pay_ins)
        setUserId(findtransactionActivity?.id)
        setDataAPI(findtransactionActivity?.APIdata)
      }
      if (!!businessDetailsReducer?.pay_outs) {
        var findtransactionpayout = transactionActivity.find((data: any) => data?.name ==businessDetailsReducer?.pay_outs)
        setLastQes(findtransactionpayout?.id)
        setDatalast(findtransactionpayout?.APIdata)
      }
      // setListQesan(newArray);

    }
  }, [rendarData])
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  console.log(!businessName && submitted == true, 'dhdhdhddhhd');
  // React.useEffect(() => {
  //   if (rendar) {
  //     var body = {
  //       user_id: user?.user_id,
  //     };
  //     // dispatch(entityActions.getBusinessDetails(body) as any);
  //     dispatch(choiceActions.getBusinessDetails(user?.user_id) as any);
  //     setRenderdata(false);
  //   }
  // }, [rendar]);
  return (
    <>
      {loading == true && (
        <Modal
          show={loading}
          onHide={onClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="ModalHeader"
          className="topup_modal"
          centered
          size="lg"
        // id={styles.maiana_contwean_abh}
        >
          <Modal.Body>
            <>
              <div className="div_pading_data">
                <img src="/logo 3.svg" />
                <p className="des_data">
                  Setting up your account. This may take a few moments. Do not
                  refresh or close this window until the process is complete.
                </p>
                {/* <Loader_icon /> */}
                <img
                  width={75}
                  height={75}
                  src="/Niobi Loading Animation-Cross.gif"
                />
              </div>
            </>
          </Modal.Body>
        </Modal>
      )}
      {/* <> */}
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid item md={3}></Grid>
          <Grid item md={6}>
            <div
              className={styles.conteanDav}
              style={{ justifyContent: 'center', height: '100%' }}
            >
              <div className={styles.hedingPage}>
                <Typography>Expected activity</Typography>
                <span className={styles.dot}></span>
              </div>
              {/* <Form className="form-input"> */}
              <Box className={styles.ConteanBox}>
                <div className="input-form-wrapper dot">
                  <label>Select your business activity</label>
                </div>
                <Form.Select
                  name="supplier_id"
                  style={{ width: '100%' }}
                  className={styles.SelectFrom}
                  //onChange={handleSubmit}
                  value={businessName}
                  // as="select"
                  onChange={(e: any) => {
                    setbusinessName(e.target.value);
                    const findName = entityActivity.find((data: any) => data.id == e.target.value)
                    console.log(findName, "findNamefindName");
                    setBusinessActivity(findName?.name)
                  }}
                  // className={'doc-form-input'}
                >
                  <option selected value="">
                    Select business activity
                  </option>
                  {Array.isArray(entityActivity)
                    ? entityActivity?.map((element: any) => {
                      return (
                        <option key={element.id} value={element.id}>
                          {element.name}
                        </option>
                      );
                    })
                    : 'data not found'}
                </Form.Select>
                {/* <Select
                  // name="business_activity"
                  id="CountryDropdown_class"
                  value={businessName}
                  // as="select"
                  onChange={(e: any) => {
                    setbusinessName(e.target.value);
                  }}
                  className={"form-text form-control select-box"}
                >
                  <option value="">--Select--</option>
                  {Array.isArray(entityActivity)
                    ? entityActivity?.map((element: any) => {
                        return (
                          <option key={element.id} value={element.id}>
                            {element.name}
                          </option>
                        );
                      })
                    : "data not found"}
                </Select> */}
                {/* {!businessName && submitted == true ? (
                  <Typography className={styles.errorMassage}>
                    Please specify the Business activity.
                  </Typography>
                ) : (
                  ""
                )} */}
              </Box>
              <Box className={styles.ConteanBox}>
                <div className="input-form-wrapper dot">
                  <label>Please describe your product offerings</label>
                </div>
                <textarea
                  id="w3review"
                  className={styles.textarea}
                  value={offerings}
                  onChange={(e: any) => {
                    setOfferings(e.target.value);
                  }}
                  name="w3review"
                  rows={6}
                  placeholder="Describe the products and services you offer your customers in a few words"
                // cols={58}
                ></textarea>
                {/* {!offerings && submitted ? (
                  <Typography className={styles.errorMassage}>error</Typography>
                ) : (
                  ""
                )} */}
              </Box>
              <Box className={styles.ConteanBox}>
                <div className="input-form-wrapper dot">
                  <label>
                    What is your expected aggregated monthly transaction
                    activity for payins?
                  </label>
                </div>
                {transactionActivity.map((data, index) => {
                  return (
                    <>
                      <Box className={styles.Box_namemain}>
                        {data.id == userId ? (
                          <input
                          type='radio'
                            className={styles.chackBox_class}
                            checked={true}
                            // indeterminate={checked[0] !== checked[1]}
                            onClick={() => { }}
                          />
                        ) : (
                          <input
                          type='radio'
                            className={styles.chackBox_class}
                            checked={data.value}
                            // indeterminate={checked[0] !== checked[1]}
                            onClick={() => {
                              setUserId(data.id);
                              setDataAPI(data?.APIdata);
                              setPayloadpayin(data?.name)
                            }}
                          />
                        )}
                        <Typography>{data.name}</Typography>
                      </Box>
                    </>
                  );
                })}
                {/* {!userId && submitted ? (
                  <Typography className={styles.errorMassage}>error</Typography>
                ) : (
                  ""
                )} */}
              </Box>
              <Box className={styles.ConteanBox}>
                <div className="input-form-wrapper dot">
                  <label>
                    What is your expected aggregated monthly transaction
                    activity for payouts?
                  </label>
                </div>
                {transactionActivity.map((data, index) => {
                  return (
                    <>
                      <Box className={styles.Box_namemain}>
                        {data.id == LastQes ? (
                          <input  type='radio'
                            className={styles.chackBox_class}
                            checked={true}
                            // indeterminate={checked[0] !== checked[1]}
                            onClick={() => { }}
                          />
                        ) : (
                          <input
                            className={styles.chackBox_class}
                          type='radio'
                            checked={data.value}
                            // indeterminate={checked[0] !== checked[1]}
                            onClick={() => {
                              setDatalast(data.APIdata);
                              setLastQes(data.id);
                              setPayloadpayout(data?.name)
                            }}
                          />
                        )}
                        <Typography>{data.name}</Typography>
                      </Box>
                    </>
                  );
                })}
                {/* {!LastQes && submitted ? (
                  <Typography className={styles.errorMassage}>error</Typography>
                ) : (
                  ""
                )} */}
              </Box>
              <div className="inputBx" style={{display:"flex",justifyContent:"center"}}>
                <button
                  type="submit"
                  //disabled={submitted}
                  style={{width:"40%"}}
                  className={styles.button_entity}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {loading == true && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Proceed
                </button>
              </div>
              {/* </Form> */}
            </div>
          </Grid>
          <Grid item md={3}></Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};
export default NewEntity;
interface EntityRequest {
  business_name: string;
  company_type: string;
  business_activity: string;
  country: string;
  street_address: string;
}
