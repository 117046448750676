import { useState, useEffect } from 'react';
import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { userActions } from '../../../_actions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../../auth/Auth.css';
import showPwdImg from '../ShowPassword/show-password.svg';
import hidePwdImg from '../ShowPassword/hide-password.svg';
import { LoginHeader } from '../LoginHeader';
import { dispatchStore } from '../../../Store/store';
import { getUser, isUserAuthenticated } from '../../../_helpers';
import { authSelector } from '../../../_reducers/authentication.reducer';
import SimpleImageSlider from 'react-simple-image-slider';
import CarouselLogin from './carousel';
import amplitude from 'amplitude-js';
import posthog from 'posthog-js';
import Layout from '../Layout';
import NewVerifyEmail from '../NewVerifyEmail/NewVerifyEmail';
import { registerSelector } from '../../../_reducers/registration.reducer';
import { Button } from 'react-bootstrap';
import { Box, Typography } from '@mui/material';

export interface ILogin { }

interface LoginValues {
    email: string;
    password: string;
    rememberMe: boolean;
    terms: boolean;
}
const images = [{ url: '1.gif' }, { url: '2.gif' }, { url: '3.gif' }];
const Login = ({path}:any) => {
    const [inputs, setInputs] = useState<LoginValues>({
        email: '',
        password: '',
        rememberMe: false,
        terms: false,
    });
    const navigate = useNavigate();
    const [loginType, setLoginType] = useState("")
    const [submitted, setSubmitted] = useState(false);
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [otpReqeust, setOtpReqeust] = useState(true);
    const [Loader, setLoader] = useState(false)
    const [requestRender, setrequestRender] = useState(true);
    const [responseRender, setresponseRender] = useState(false);
    const [loading, setLoading] = useState(false);
    const [autoShow, setAutoShow] = useState(false);
    const [email, setEmail] = useState("")
    const [Password, setPassword] = useState("")
    const [submitData, setSubmitData] = useState(false)
    const [setp, setStep] = useState(0)
    const localUser: any = localStorage.getItem('user');

    const APIData = useSelector((state) => authSelector(state));
    const registerData = useSelector((state) => registerSelector(state));

    console.log(registerData?.SEND_OTP_SUCCESS, loginType, setp, otpReqeust, "registerDataregisterData");

    // useEffect(() => {
    //     if (requestRender && APIData?.LOGIN_REQUEST == true) {
    //         setLoading(APIData.LOGIN_REQUEST);
    //         setresponseRender(true);
    //         setrequestRender(false);
    //     }
    // }, [APIData, requestRender]);

    // useEffect(() => {
    //     if (responseRender && APIData?.LOGIN_REQUEST == false) {
    //         setLoading(false);
    //         setrequestRender(true);
    //         setresponseRender(false);
    //     }
    // }, [APIData, requestRender]);

    const dispatch = useDispatch();
    const location = useLocation();

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Please enter a valid email address.')
            .required('Please enter a valid email address.'),
        password: Yup.string().required('Please enter your password.'),

    });

    const user = getUser()?.data ?? null;

    useEffect(() => {
        const useQuery = new URLSearchParams(location.search);
        let query = useQuery.get('data');
        if (!!query && query == 'auto') {
            setAutoShow(true);
        } else {
            setAutoShow(false);
        }
    }, []);
    function isValidEmail(email: any) {
        // Define a regular expression pattern for email validation.
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    }
    useEffect(() => {
        if (user && isUserAuthenticated()) {
            navigate(user.hasEntity ? '/dashboard' : '/v2/account-setup');
        }
    }, []);
    const handleSubmit_resend = (data: any) => {
        console.log(data, "props?.loginType");

        var body = {
            "type": data,
            "email": email
        }
        dispatch(userActions.ResendOtp(body) as any);
        setLoader(true)
        setTimeout(() => {
            setOtpReqeust(true)
        }, 4000)
    }

    const handleSubmit = (data: any) => {
        setSubmitted(true);
        console.log(Password, email, data, isValidEmail(email), "isValidEmail(email)");

        if (!!Password && !!email && !!data && !!isValidEmail(email)) {
            const event = 'User Logged In';
            const eventProperties = {
                userId: null,
                userName: null,
                email: !!email ? email : null,
                pageUrl: window.location.href,
            };
            amplitude.getInstance().logEvent(event, eventProperties);
            posthog.capture(event, eventProperties);
            const { from } = location.state || { from: { pathname: '/' } };
            var body = {
                "type": data,
                "email": email,
                "password": Password,
            }
            setLoginType(data)
            setLoading(true)
            dispatch(userActions.sendOtp(body) as any);
            setLoader(true)
            setTimeout(() => {
                setOtpReqeust(true)
            }, 4000)
        } else {
            setSubmitData(true)
        }

    };
    console.log(otpReqeust && registerData?.SEND_OTP_SUCCESS !== undefined, otpReqeust, registerData?.SEND_OTP_SUCCESS, Loader, "");

    useEffect(() => {
        if (otpReqeust && registerData?.SEND_OTP_SUCCESS !== undefined) {
            setOtpReqeust(false)
            setLoader(false)
            if (loginType == "email") {
                setStep(2)
            } else if (loginType == "mobile") {
                setStep(1)
            }
        }
    }, [otpReqeust, registerData])
    useEffect(() => {
        if (otpReqeust && registerData?.SEND_OTP_FAILURE !== undefined) {
            setOtpReqeust(false)
            setLoader(false)
        }
    }, [otpReqeust, registerData])

    return (
        <>
            {/* {Loader &&
                <div
                    style={{
                        display: 'flex',
                        position: 'absolute',
                        top: 150,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 10,
                    }}
                >
                    <img width={75} height={75} src="/Niobi Loading Animation-Cross.gif" />
                </div>} */}
            <Layout>

                {setp == 0 ?
                    <div className="formBx_tow">

                        {/* <Formik
                            initialValues={inputs}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ errors, touched, isSubmitting, handleChange, values }) => (
                                <Form className="form-input_line"> */}
                        <Box className="form-input_line">
                            <div id="req_text_atage" className="inputBx" style={{ padding: "50px 0px" }}>
                                <p style={{ fontSize: "12px" }}>
                                    Don't have an account?{' '}
                                    <Link style={{ textDecoration: "none", fontWeight: "600", fontSize: "12PX" }} to="/register">
                                        Get Started.
                                    </Link>
                                </p>
                            </div>
                            <img className='logoPage' src="/assets/sidebar-logo.png" alt="logo" />
                            <div className="classname_login_page" >
                               
                                <div className="login" style={{ textAlign: "left", paddingBottom: "20px" }}>
                                    <p>Log In</p>
                                </div>
                                <div className="inputBx">
                                    <span>Email</span>
                                    <input
                                        name="email"
                                        type="text"
                                        placeholder="Email"
                                        className={
                                            'form-text form-control'
                                            //  +
                                            // (errors.email && touched.email ? ' is-invalid' : '')
                                        }
                                        value={email}
                                        onChange={(e: any) => {
                                            setEmail(e.target.value)
                                        }}
                                    />
                                    {submitData && !email ?
                                        <Typography className="error_maessge">Please enter a valid email address.</Typography>
                                        : submitData && !isValidEmail(email) ?
                                            <Typography className="error_maessge">Please enter a valid email address.</Typography>
                                            : ""}
                                </div>
                                <div className="inputBx">
                                    <span>Password</span>
                                    <div className="pwd-container">
                                        <input
                                            name="password"
                                            placeholder="Password"
                                            type={isRevealPwd ? 'text' : 'password'}
                                            className={
                                                'form-text form-control'
                                                // +
                                                // (errors.password && touched.password
                                                //     ? ' is-invalid'
                                                //     : '')
                                            }
                                            value={Password}
                                            onChange={(e: any) => {
                                                setPassword(e.target.value)
                                            }}
                                        />
                                        <img
                                            title={
                                                isRevealPwd ? 'Hide password' : 'Show password'
                                            }
                                            src={isRevealPwd ? hidePwdImg : showPwdImg}
                                            onClick={() =>
                                                setIsRevealPwd((prevState) => !prevState)
                                            }
                                        />
                                        {submitData && !Password ?
                                            <Typography className="error_maessge">Please enter your password.</Typography> : ""}
                                    </div>
                                </div>
                                <div style={{ justifyContent: "end" }} className="remember">
                                    <span>
                                        <Link className="link" to="../forgotpassword">
                                            Forgot password
                                        </Link>
                                    </span>
                                </div>

                                {/* {autoShow == true ? (
                                            <div className="auto-logout-text">
                                                You were logged out automatically for inactivity
                                            </div>
                                        ) : (
                                            ''
                                        )} */}
                                <div className="inputBx" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Button
                                        type="submit"
                                        className="button_with_SMS"
                                        style={{ width: "48%", fontWeight: "400", fontSize: "15px" }}
                                        onClick={() => {
                                            handleSubmit("mobile")
                                        }}
                                    >
                                        {loading == true && (
                                            <span className="spinner-border spinner-border-sm mr-1"></span>
                                        )}
                                        <span>Log In with SMS</span>
                                    </Button>
                                    <Button
                                        type="submit"
                                        className="inputBx form-button"
                                        style={{ width: "48%", fontWeight: "400", fontSize: "15px", color: "#fff" }}
                                        onClick={() => {
                                            handleSubmit("email")
                                        }}
                                    >
                                        {loading == true && (
                                            <span style={{ fontSize: "15px", fontWeight: "400", color: "#fff" }} className="spinner-border spinner-border-sm mr-1"></span>
                                        )}
                                        <span>Log In with Email</span>
                                    </Button>
                                </div>

                            </div>
                            <div className="footer_text" style={{ paddingTop: "20px" }}>
                                <a
                                    id="a_tageStyles"
                                    style={{ textDecoration: "none", paddingRight: "4px", fontSize: "12px", color: "#000" }}
                                    target="_blank"
                                    className=""
                                    href="https://niobi.co/wp-content/uploads/2023/02/Niobi_Terms-of-Use_v1.1.pdf"
                                >
                                    Terms of Use
                                </a>{' '}/{' '}<a
                                    id="a_tageStyles"
                                    style={{ textDecoration: "none", paddingLeft: "4px", fontSize: "12px", color: "#000" }}
                                    target="_blank"
                                    className=""
                                    href="https://niobi.co/wp-content/uploads/2023/02/Niobi_Privacy-Policy_v1.1.pdf"
                                >
                                    Privacy Policy
                                </a>
                            </div>
                        </Box>
                        {/* </Form>
                            )}
                        </Formik> */}
                        <LoginHeader component="login" />
                    </div> : <NewVerifyEmail    path={path} type={loginType} email={email} setLoginType={setLoginType} password={Password} setStep={setStep} setp={setp} handleSubmit_resend={handleSubmit_resend} />}
            </Layout>
        </>

    );
};

export default Login;
