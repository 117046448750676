import React, { useEffect } from 'react';
//import Layout from 'features/app/Layout';

import Layout from '..';
import { Outlet, useNavigate } from 'react-router-dom';
import { getUser, isUserAuthenticated } from '../../../../_helpers';
import { userActions } from '../../../../_actions';
import { dispatchStore } from '../../../../Store/store';

type EmptyPageProps = {
  label?: string;
  renderIcon?: (props: any) => React.ReactNode;
  apiLink?: string;
};

const NavPage = ({ label, renderIcon, apiLink = '' }: EmptyPageProps) => {
  const navigate = useNavigate();
  const user = getUser()?.data ?? null;

  useEffect(() => {
    if (!user && !isUserAuthenticated()) {
      navigate('/login');
    } else if (
      user &&
      isUserAuthenticated() &&
      window.location.pathname === '/'
    ) {
      navigate(user.hasEntity ? '/dashboard' : '/v2/account-setup');
    }
  }, [user, navigate]);
  const isLogged = sessionStorage.getItem('logged');
  console.log(isLogged, 'isLoggedisLogged');

  React.useEffect(() => {
    if (!isLogged) {
      dispatchStore(userActions.logout('false',"Your session has expired. Kindly login again.") as any);
    }
  }, [isLogged]);
  return (
    <Layout>
      {/* <div className={styles.topNav}>
        </div> */}
      <Outlet />
    </Layout>
  );
};

export default NavPage;
