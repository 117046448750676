import { configureStore, Dispatch } from '@reduxjs/toolkit';
import {
  registration,
  updatePassword,
  forgotPassword,
  verifyToken,
  changePassword,
  getOtp,
  registrationByInviteReducer,
  loginOtpVerifyReducer,
  sendOtpReducer,
  ResendOtpReducer,
  saveCurrencies,
  registrationV2
} from '../_reducers/registration.reducer';
import { authentication } from '../_reducers/authentication.reducer';
import {
  getEntityActivitiesReducer,
  getEntityTypesReducer,
  createEntityReducer,
  updateEntity,
  getEntities,
  getBillsslist,
  getBusinessDetails,
  getCurrentStepReducer,
  setCurrentStepReduser,
  getDetailsForNewAPIReducer,
  saveBusinessDetailsReducers,
  updetDataReduser,
  saveEntityReducer,
  createEntityV2Reducer
} from '../_reducers/entity.reducer';
import {
  getBalanceReducer,
  getBillReducer,
  getChartReducer,
  documentverificationReducer,
  getBalance_currency_rateReducer,
  getRecentReducer,
  getBudgetdetailsReducer,
  getUpdatedetailsReducer,
  getCategoryExpensesReducer,
  getPayeeExpensesReducer,
  getPaymentExpensesReducer,
  getBillpayeeReducer,
  getPayableToApproveReducer,
  getPayableToRejectReducer,
  getPayableToPendingReducer,
  getPayableToUpdateReducer,
  getPayableToCancelReducer,
  getPayableToPayReducer,
  getPayinsReducer,
  getPaymentMethodPayinsReducer,
  getunifiedPaymentChannelsReducer
} from '../_reducers/dashboard.reducer';
import {
  getBalanceListReducer,
  getBalanceListReducertwo,
  getTopUpDetailsReducer,
  getCsvExportReducer,
  getBankDetailsByIdReducer,
  savePaymentDetailsReducer,
  Stk_deposit_Reducer,
  getpdf_resucer,
  getpdf_Topup_resucer,
  PostKasPaymentReducer,
  getTopUpDetails_DollerReducer,
  savePaymentcurrencyDetailsReducer,
  transtopup_reducer,
  getpdf_Topup_Stk_resucer,
} from '../_reducers/balance.reducer';
import {
  getCounterpartsListReducer,
  getTagsListReducer,
  getUserCounterpartsReducer,
  getPendingPayblesReducer,
  updatePayblesReducer,
  submitPayblesReducer,
  getTagsListbudgetReducer,
  getVatReducer,
  getbulk_payment_payables_Reducer,
  updatebulk_paymentReducer,
  rejectbulk_paymentReducer,
  ceretePayblesReducer,
  getCounterpartsList_DATAReducer,
  getEditCounterpartsListeducer,
  updateCounterpartsReducer,
} from '../_reducers/counterparts.reducer';
import {
  getPayablesListReducer,
  payablesCreate,
  payablesCreateOTP,
  setSelectedItem,
  initailPaymentReducer,
  bulkpayables,
  getTransactionsReducer,
  getOtpvirifay,
  bulkbills,
  getTransactionsReducer_mass_data,
  getpayable_id_reducer,
  create_recurring_bill_Reducer,
  initailPayment_BULKReducer,
  submit_recurring_bill_Reducer,
  transactionExistReducer
} from '../_reducers/payables.reducer';
import {
  getFeesReducer,
  getFeesNewReducer,
  getInveact,
  getPesaBankListReducer,
  getIntegration_Reducer,
  AddBankDisbursementReducer,
  Express_Payment_Reducer,
  AddBankDisbursementReducer_Exapes,
  Create_integration_Reducer,
  Create_credentials_Reducer,
  getIntegration_Apikey_Reducer,
  delete_credentials_Reducer,
  getdurationlist_Reducer,
  Create_budget_Reducer,
  budget_list_data_Reducer,
  getBulk_payment_reducer,
  getbulk_payments_status_activeReducer,
  edit_budget_Reducer,
  delete_budget__Reducer,
  budget_viwe_list_Reducer,
  getSave_data,
  get_accounting_types_Reducer,
  create_connectionReducer,
  get_connections_Reducer,
  disable_connectionReducer,
  getPaybillNewReducer,
  getPaymentLinkByShortcode_reducer,
  paylink_reducer,
  transpaylink_reducer,
  transpaylinkPDF_reducer,
  invoicesend_reducer,
  invoicesendreceiver_reducer,
  Bankdetails_reducer,
  PaymentLinkApiListreducer,
  paymentLinkApiCreate_reducer,
  getShareablePdfLinkReducer,
  PaymentLinkApiDeleteReducer,
  PaymentLinkApiUpdateReducer,
  StanbicVerifyAccountReducer,
  getCountriesReducer,
  getCountriesDetailsReducer,
  getCurrenciesReducer,
  getCountriesByCurrencyReducer,
  AddBankUSDReducer,
  ChackBalanceReducer,
  getCountriesBalancesReducer,
  checkTransactionTypeReducer,
  NiobiSignature_Reducer,
  getCurrenciesV2Reducer,
  getCountriesReducerV2,
  ChackBalancepaymentLinkReducer,
  getValidateAccountReducer,
  getAllCurrenciesDetailsReducer
} from '../_reducers/paymentJourney.reducer';
import {
  getChoiceTypesReducer,//
  getBusinessOperationModesReducer,//
  getGenderReducer,//
  getIndustriesReducer,//
  createBusinessReducer,
  verifyOtpReducer,
  resendOtpReducer,
  uploadMediaReducer,
  getOnboardingInfoReducer,
  getBusinessInfoReducer,
  saveShareholderReducer,
  saveBusinessDetailsReducer,
  getBusinessDetailsReducer,
  submitToBankReducer,
} from '../_reducers/choiceBank.reducer';
import {
  getDocuments,
  storeDocuments,
  submitDocuments,
  getEntityDocumentsReducer,
  submitEntityDocumentsReducer,
  getUserDocuments,
  getRolsDocumentsReducer,
  getPermissionsDocumentsReducer,
  InviteDocuments,
  getUserDetailReducer,
  findUserDetailReducer,
  delete_data_Documents,
  getTeamDocumentsReducer,
  getTeamByIdReducer,
  getUserteamDocuments,
} from '../_reducers/setting.reducer';

export const store = configureStore({
  reducer: {
    authentication,
    registration,
    getEntityTypesReducer,
    getEntityActivitiesReducer,
    createEntityReducer,
    getBalanceReducer,
    getBillReducer,
    getChartReducer,
    getBalanceListReducer,
    getCounterpartsListReducer,
    payablesCreate,
    getTagsListReducer,
    getUserCounterpartsReducer,
    getPayablesListReducer,
    getFeesReducer,
    setSelectedItem,
    getTopUpDetailsReducer,
    payablesCreateOTP,
    initailPaymentReducer,
    getTransactionsReducer,
    updatePassword,
    updateEntity,
    getEntities,
    forgotPassword,
    verifyToken,
    changePassword,
    getOtp,
    getFeesNewReducer,
    getDocuments,
    storeDocuments,
    submitDocuments,
    getInveact,
    bulkpayables,
    getBalanceListReducertwo,
    getOtpvirifay,
    getBillsslist,
    getCsvExportReducer,
    getEntityDocumentsReducer,
    submitEntityDocumentsReducer,
    bulkbills,
    getUserDocuments,
    getRolsDocumentsReducer,
    getPermissionsDocumentsReducer,
    InviteDocuments,
    registrationByInviteReducer,
    getBusinessDetails,
    getUserDetailReducer,
    getBankDetailsByIdReducer,
    savePaymentDetailsReducer,
    findUserDetailReducer,
    getPendingPayblesReducer,
    updatePayblesReducer,
    submitPayblesReducer,
    getPesaBankListReducer,
    AddBankDisbursementReducer,
    Express_Payment_Reducer,
    AddBankDisbursementReducer_Exapes,
    Create_integration_Reducer,
    getIntegration_Reducer,
    Create_credentials_Reducer,
    getIntegration_Apikey_Reducer,
    delete_credentials_Reducer,
    Stk_deposit_Reducer,
    getTransactionsReducer_mass_data,
    getdurationlist_Reducer,
    Create_budget_Reducer,
    getTagsListbudgetReducer,
    budget_list_data_Reducer,
    getpdf_resucer,
    getpdf_Topup_resucer,
    getpayable_id_reducer,
    getVatReducer,
    create_recurring_bill_Reducer,
    submit_recurring_bill_Reducer,
    delete_data_Documents,
    budget_viwe_list_Reducer,
    delete_budget__Reducer,
    edit_budget_Reducer,
    getBulk_payment_reducer,
    getbulk_payment_payables_Reducer,
    updatebulk_paymentReducer,
    initailPayment_BULKReducer,
    getbulk_payments_status_activeReducer,
    rejectbulk_paymentReducer,
    ceretePayblesReducer,
    getSave_data,
    getCounterpartsList_DATAReducer,
    getEditCounterpartsListeducer,
    updateCounterpartsReducer,
    getPaybillNewReducer,
    get_accounting_types_Reducer,
    create_connectionReducer,
    get_connections_Reducer,
    disable_connectionReducer,
    PostKasPaymentReducer,
    getTopUpDetails_DollerReducer,
    savePaymentcurrencyDetailsReducer,
    documentverificationReducer,
    getBalance_currency_rateReducer,
    getRecentReducer,
    getBudgetdetailsReducer,
    getUpdatedetailsReducer,
    getCategoryExpensesReducer,
    getPayeeExpensesReducer,
    getPaymentExpensesReducer,
    getTeamDocumentsReducer,
    getTeamByIdReducer,
    getUserteamDocuments,
    getPaymentLinkByShortcode_reducer,
    paylink_reducer,
    transpaylink_reducer,
    transtopup_reducer,
    transpaylinkPDF_reducer,
    getpdf_Topup_Stk_resucer,
    invoicesend_reducer,
    invoicesendreceiver_reducer,
    Bankdetails_reducer,
    getBillpayeeReducer,
    getPayableToApproveReducer,
    getPayableToRejectReducer,
    getPayableToPendingReducer,
    getPayableToUpdateReducer,
    getPayableToCancelReducer,
    getPayableToPayReducer,
    PaymentLinkApiListreducer,
    paymentLinkApiCreate_reducer,
    getShareablePdfLinkReducer,
    PaymentLinkApiDeleteReducer,
    PaymentLinkApiUpdateReducer,
    getChoiceTypesReducer,
    getBusinessOperationModesReducer,
    getGenderReducer,
    getIndustriesReducer,
    createBusinessReducer,
    StanbicVerifyAccountReducer,
    verifyOtpReducer,
    resendOtpReducer,
    uploadMediaReducer,
    getOnboardingInfoReducer,
    getCountriesReducer,
    getCountriesDetailsReducer,
    getCurrenciesReducer,
    getCountriesByCurrencyReducer,
    AddBankUSDReducer,
    ChackBalanceReducer,
    getCountriesBalancesReducer,
    getBusinessInfoReducer,
    loginOtpVerifyReducer,
    sendOtpReducer,
    ResendOtpReducer,
    saveShareholderReducer,
    getPaymentMethodPayinsReducer,
    getPayinsReducer,
    getunifiedPaymentChannelsReducer,
    checkTransactionTypeReducer,
    transactionExistReducer,
    NiobiSignature_Reducer,
    getCurrenciesV2Reducer,
    getCountriesReducerV2,
    ChackBalancepaymentLinkReducer,
    getValidateAccountReducer,
    getCurrentStepReducer,
    setCurrentStepReduser,
    getDetailsForNewAPIReducer,
    saveBusinessDetailsReducers,
    getAllCurrenciesDetailsReducer,
    saveCurrencies,
    registrationV2,
    updetDataReduser,
    saveEntityReducer,
    saveBusinessDetailsReducer,
    getBusinessDetailsReducer,
    submitToBankReducer,
    createEntityV2Reducer
  },
});

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch

// create your own dispatch function reference with custom typings
export const dispatchStore = store.dispatch as typeof store.dispatch;
