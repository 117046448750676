import { Avatar, Grid, Typography } from '@mui/material';
import styles from './ChooseCurrency.module.scss';
import { Button } from 'react-bootstrap';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


const ChooseCurrency = (props: any) => {
    const navigate = useNavigate();

    const [selcetCurrency, setSelectCurrency] = useState("")
    console.log(props, "propspropsprops1323");


    
    return (
        <>
            <Grid container >
                <Grid item md={3}>

                </Grid>
                <Grid item md={6} sm={12} xs={12}  className={styles.contean_grid}>
                    <Typography className={styles.heading_text}>Choose Currency</Typography>
                    <Grid container className={styles.grid_currency_box}>
                        {props?.countryList?.map((element: any, index: number) => {

                            return (
                                <Grid item md={6} sm={12} xs={12} className={styles.grid_button_contean}>
                                    <Button  style={selcetCurrency == element.currency ? { background: "#fff", border: "1px solid #025041",padding:"12px 16px" } : { background: "#F2F8F7",padding:"12px 16px" }}
                                        onClick={() => {
                                            // props.setCurrancylist(element.currency)
                                            setSelectCurrency(element.currency)
                                            props.onSavecurrancy(element.currency)
                                            navigate(`/balance?currency=${element.currency}`);

                                        }}
                                        className={styles.box_currency}>
                                        <div className={styles.currency_img}>
                                            <img src={element.flag} />
                                        </div>
                                        <div className={styles.main_divtext}>
                                            <Typography className={styles.currency_text}>
                                                {element.currency}
                                            </Typography>
                                            <Typography className={styles.country_text}>
                                                {element.name}
                                            </Typography>
                                            <Typography className={styles.Typography_clsamount}>
                                            {element.currency} {!!element && element.total_balance
                                                    ? (element.total_balance / 100).toLocaleString(
                                                        'en',
                                                        { minimumFractionDigits: 2 }
                                                    )
                                                    : '0.00'}

                                            </Typography>
                                        </div>
                                    </Button>
                                </Grid>
                            )
                        })}
                        <Grid item md={6}>

                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={3}>

                </Grid>
            </Grid>
        </>
    )
}
export default ChooseCurrency;