import { Box, Grid } from '@mui/material';
import styles from './Link_tebal.module.scss';
import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import Tebal from './components/tebal/tebal';
import { useDispatch, useSelector } from 'react-redux';
import { paymentJourneyActions } from '../../../../../../_actions/paymentJourney.actions';
import {
  getUser,
  isUserAuthenticated,
  authHeader,
} from '../../../../../../_helpers';
import axios from 'axios';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CssBaseline, CircularProgress } from '@mui/material';
import { getEnvironment } from '../../../../../../_helpers/environmentProperties';
import { SkeletonDashboardV3 } from '../../../Skeleton/Skeleton';

const baseURL = 
  getEnvironment() === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_STAGING_API_URL;

const SearchIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 13L10.0748 10.1341M11.6667 6.33334C11.6667 9.27886 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27886 1 6.33334C1 3.38782 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38782 11.6667 6.33334Z"
      stroke="#111111"
      stroke-width="1.1875"
      stroke-miterlimit="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const Link_tebal = () => {
  const [search, setSearch] = React.useState('');
  const [loderinapi, setLoderapi] = React.useState(false);
  const [row, setRow] = useState();
  const [totalCount, setTotalCount] = useState(10);
  const [redusar, setReduser] = useState(true);
  const [pageneshn, setPagelist] = useState(1);
  const [remove, setRemovedata] = useState(10);
  const [tagsList, setTagsList] = useState([
    {
      name: 'Active',
      id: 'active',
    },
    {
      name: 'Inactive',
      id: 'inactive',
    },
  ]);
  const [statusFilter, setStatusFilter] = useState('');
  const [Loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  console.log(row, 'rowrowrow');

  useEffect(() => {
    if (redusar) {
      setReduser(false);
      var serdata: any = {
        search: search,
        pagedata: pageneshn,
        status: statusFilter,
      };
      getBalanceList(serdata);
    }
  }, [redusar]);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      var serdata: any = {
        search: search,
        pagedata: pageneshn,
        status: statusFilter,
      };
      getBalanceList(serdata);
      console.log('usereffect testing');
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [search, pageneshn, statusFilter]);
  const getBalanceList = async (datalist: any) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
    };
    var pagelimit = 10;
    setLoderapi(true);
    const response = await axios
      .get(
        `${baseURL}/payment-link-api/list?search=${datalist.search}&page=${datalist.pagedata}&status=${datalist.status}`,
        requestOptions
      )
      .then((response) => {
        if (response.data.success == true) {
          console.log(response, 'responseresponse');

          setRow(response.data.data.data);
          setTotalCount(response.data.data.total);
          setTimeout(() => {
            setLoderapi(false)
          }, 5000)
        } else {
          console.log('errr');
          setTimeout(() => {
            setLoderapi(false)
          }, 5000)
        }
        console.log(response, 'response');
        // setLoderapi(false)
      })
      .catch(err=>console.error(err))
  };
  return (
    <>
      <Grid container className={styles.container_paymentlink}>
        {/* {loderinapi && (
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              top: 150,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 10,
            }}
          >
            <CircularProgress color="success" />
          </Box>
        )} */}
             <SkeletonDashboardV3 width={'100%'} height={'60px'} loader={loderinapi}
          element={
            <>
        <Grid item md={3.5} sm={12} xs={12}>
          <InputGroup className="balance__filter-wrapper input">
            <InputGroup.Text id="basic-addon1" className="icon">
              <SearchIcon />
            </InputGroup.Text>
            <Form.Control
              className="balance-search-input"
              placeholder="Search by Payment URL"
              aria-label="Search"
              aria-describedby="basic-addon1"
              // onChange={handleSearch}

              disabled={loderinapi}
              onChange={(event) => {
                console.log('testing');
                if (event.target.value == '') {
                  setSearch(event.target.value);
                  // getBalanceList(event.target.value)
                  // setTimeout(() => { setCounterpartsListRender_two(true) }, 3000)
                } else {
                  setSearch(event.target.value);
                  // getBalanceList(event.target.value)
                }
                // handleSearch(event.target.value, startDate, endDate);
              }}
              // onKeyDown={() => clearTimeout(typingTimer)}
            />
          </InputGroup>
        </Grid>
     
        <Grid item md={0.3} sm={0} xs={0}></Grid>
       
        <Grid item md={2.5} sm={12} xs={12}>
          <div className=" balance_cat">
            <Form.Select
            style={{fontSize:"11px"}}
              onChange={(e: any) => {
                setStatusFilter(e.target.value);
              }}
            >
              <option selected value="">
                Select status
              </option>
              {tagsList?.map((arrayElement: any, index: number) => {
                return (
                  <option value={arrayElement.name} key={index + 1}>
                    {arrayElement.name}
                  </option>
                );
              })}
            </Form.Select>
          </div>
        </Grid>
        <Grid item md={4.5} sm={0} xs={0}></Grid>
        </>}></SkeletonDashboardV3>
        <Grid item md={12} sm={12} xs={12}>
        <SkeletonDashboardV3
          height={'650px'}
          width={'100%'}
          loader={loderinapi}
            element={
              <>
          <Box className={styles.box_tebalbottom}>
            <Tebal data={row} />
          </Box>
          <div className="addnewbtnclass" style={{ paddingTop: '10px' }}>
            <div className="inpagenum">
              <p>
                {remove - 9}-{remove}
              </p>
            </div>
            <div className="btnmaindivin">
              {pageneshn == 1 ? (
                <button disabled className="nextntmistish">
                  <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                </button>
              ) : (
                <button
                  className="nextntmistish"
                  onClick={() => {
                    setPagelist(pageneshn - 1);
                    setRemovedata(remove - 10);
                  }}
                >
                  <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                </button>
              )}
              {remove >= totalCount ? (
                <button disabled className="nextntmistish">
                  <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                </button>
              ) : (
                <button
                  className="nextntmistish"
                  onClick={() => {
                    setPagelist(pageneshn + 1);
                    setRemovedata(remove + 10);
                  }}
                >
                  <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                </button>
              )}
            </div>
          </div>
          </>}></SkeletonDashboardV3>
        </Grid>
      </Grid>
    </>
  );
};
export default Link_tebal;
