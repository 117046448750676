import { alertActions } from '.';
import { settingService } from '../_services/setting.service';
import { settingConstants } from '../_constants/setting.constants';
import toast, { Toaster } from 'react-hot-toast';
import { Unauthorized } from '../_constants/ErrorMessage';
import { dispatchStore } from '../Store/store';
import { userActions } from '../_actions';

export const settingActions = {
  getBusinessDeails,
  getDocuments,
  submitDocuments,
  storeDocuments,
  getEntityDocuments,
  submitEntityDocuments,
  deleteEntityDocuments,
  getUserDocuments,
  getInviteDocuments,
  getRolsDocuments,
  getPermissionsDocuments,
  deleteUser,
  getUserDetail,
  updateUser,
  findUserDetail,
  deleteInactiveUser,
  deleteDocuments,
  getTeamDocuments,
  Team_cerete,
  getTeamById,
  deleteInactiveTaem,
  updateTeam,
  getUserteamDocuments,
};

function getBusinessDeails(entity_id: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    // dispatch(request())

    settingService
      .getBusinessDeails(entity_id)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          dispatch(success(response.data));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: settingConstants.GET_BUSINESS_DETAILS_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.GET_BUSINESS_DETAILS_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: settingConstants.GET_BUSINESS_DETAILS_FAILURE };
  }
}

function getDocuments(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    settingService
      .getDocuments(req)
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
            toast.error(transaction.message, { duration: 8000 });
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() {
    return { type: settingConstants.GETDOCUMENT_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.GETDOCUMENT_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: settingConstants.GETDOCUMENT_FAILURE };
  }
}
function storeDocuments(document: any) {
  return (dispatch: (arg0: any) => void) => {
    dispatch(request(document));

    settingService
      .storeDocuments(document)
      .then((response) => {
        return response.json();
      })
      .then(
        (document) => {
          if (document.success) {
            dispatch(success(document));
            dispatch(alertActions.success('Store documents successful'));
          } else {
            dispatch(failure(document.message));
            dispatch(alertActions.error(document.message));

            if (document.message == 'Validation errors') {
              var fieldarray = ['entity_id"', 'type', 'documents'];
              fieldarray.map((field: any) => {
                if (!!document.data[field]) {
                  document.data[field].map((i: string) => {
                    toast.error(i, { duration: 8000 });
                  });
                }
              });
            }
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(document: any) {
    return { type: settingConstants.STOREDOCUMENT_REQUEST, document };
  }
  function success(document: any) {
    return { type: settingConstants.STOREDOCUMENT_SUCCESS, document };
  }
  function failure(error: any) {
    return { type: settingConstants.STOREDOCUMENT_FAILURE, error };
  }
}
function submitDocuments(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    settingService
      .submitDocuments(req)
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
            toast.error(transaction.message, { duration: 8000 });
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() {
    return { type: settingConstants.SUBMITDOCUMENT_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.SUBMITDOCUMENT_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: settingConstants.SUBMITDOCUMENT_FAILURE };
  }
}
function submitEntityDocuments() {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    settingService
      .submitEntityDocuments()
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
            toast.error(transaction.message, { duration: 8000 });
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() {
    return { type: settingConstants.SUBMITENTITYDOCUMENT_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.SUBMITENTITYDOCUMENT_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: settingConstants.SUBMITENTITYDOCUMENT_FAILURE };
  }
}

function deleteEntityDocuments(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    settingService
      .deleteEntityDocuments(req)
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
            toast.error(transaction.message, { duration: 8000 });
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() {
    return { type: settingConstants.DELETEENTITYDOCUMENT_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.DELETEENTITYDOCUMENT_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: settingConstants.DELETEENTITYDOCUMENT_FAILURE };
  }
}
function deleteDocuments(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    settingService
      .delete_document(req)
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
            toast.error(transaction.message, { duration: 8000 });
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() {
    return { type: settingConstants.DELETE_DOCUMENT_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.DELETE_DOCUMENT_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: settingConstants.DELETE_DOCUMENT_FAILURE };
  }
}
function getEntityDocuments() {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    settingService
      .getEntityDocuments()
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            console.log('transaction:', transaction);
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));

            var localStorageUser: any = localStorage.getItem("user")
            console.log(transaction.message, "transaction.message");

            if (
              transaction.message ==
              'Your session has expired. Kindly log in again.'
            ) {
              localStorage.removeItem('user');
              localStorage.removeItem('Bulkdata');
              localStorage.removeItem('Bulkdatatwo');
              localStorage.removeItem('Bulk_Payment');
              localStorage.removeItem('token');
              localStorage.removeItem('tokentwo');
              localStorage.removeItem('user_verification');
              window.location.href = '/login';
              toast.error(transaction.message);
            }

            // toast.error(transaction.message, { duration: 8000 });
          }
        },
        (error) => {
          // toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() {
    return { type: settingConstants.GETENTITYDOCUMENT_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.GETENTITYDOCUMENT_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: settingConstants.GETENTITYDOCUMENT_FAILURE };
  }
}
function getRolsDocuments() {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    settingService
      .getRolsDocuments()
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction.data));
            dispatch(alertActions.success('Transactions Success'));
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
            toast.error(transaction.message, { duration: 8000 });
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() {
    return { type: settingConstants.ROLSDATA_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.ROLSDATA_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: settingConstants.ROLSDATA_FAILURE };
  }
}
function getPermissionsDocuments() {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    settingService
      .getPermissionsDocuments()
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
            toast.error(transaction.message, { duration: 8000 });
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() {
    return { type: settingConstants.PERMISSIONS_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.PERMISSIONS_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: settingConstants.PERMISSIONS_FAILURE };
  }
}
function getInviteDocuments(datarols: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    settingService
      .getInviteDocuments(datarols)
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
            if (transaction.message == 'Validation errors') {
              const objToArray: any = Object.values(transaction.data);
              toast.error(objToArray[0], { duration: 8000 });
            }
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() {
    return { type: settingConstants.INVITEDOCUMENT_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.INVITEDOCUMENT_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: settingConstants.INVITEDOCUMENT_FAILURE };
  }
}
function getUserDocuments(data: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    settingService
      .getUserDocuments(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          console.log(transaction.success, 'transactiontransaction');

          if (transaction.success) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
          } else {
            dispatch(success(transaction));
            dispatch(alertActions.error(transaction.message));
            toast.error(transaction.message, { duration: 8000 });
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() {
    return { type: settingConstants.USERDOCUMENT_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.USERDOCUMENT_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: settingConstants.USERDOCUMENT_FAILURE };
  }
}
function getUserteamDocuments(data: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    settingService
      .getUserteamDocuments(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          console.log(transaction.success, 'transactiontransaction');

          if (transaction.success) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
          } else {
            dispatch(failure(transaction));
            dispatch(alertActions.error(transaction.message));
            toast.error(transaction.message, { duration: 8000 });
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() {
    return { type: settingConstants.USERTEAMDOCUMENT_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.USERTEAMDOCUMENT_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: settingConstants.USERTEAMDOCUMENT_FAILURE };
  }
}
function getTeamDocuments() {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    settingService
      .getTeamDocuments()
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction.data));
            dispatch(alertActions.success('Transactions Success'));
          } else {
            dispatch(failure(transaction));
            dispatch(alertActions.error(transaction.message));
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() {
    return { type: settingConstants.TEAMDOCUMENT_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.TEAMDOCUMENT_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: settingConstants.TEAMDOCUMENT_FAILURE, error };
  }
}
function deleteUser(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    settingService
      .deleteUser(req)
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
            toast.success(transaction.message, { duration: 8000 });
            window.location.href = '/settings/team';
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
            toast.error(transaction.message, { duration: 8000 });
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(req: any) {
    return { type: settingConstants.DELETEUSER_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.DELETEUSER_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: settingConstants.DELETEUSER_FAILURE };
  }
}
function getUserDetail(req: any, condisan: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    settingService
      .getUserDetail(req)
      .then(async (data) => {
        dispatch(success(undefined));
        return { response: await data.json(), raw: data }
      })
      .then(
        (responseData: any) => {
          var response = responseData?.response;
          var statusCode = responseData?.raw?.status
          console.log(statusCode, response, "getdata123");
          if (statusCode == 455 && condisan == "yes") {
            dispatchStore(userActions.logout('auto', "Your session has expired. Kindly login again.") as any);
          } else {
            dispatch(success(response.data));
            dispatch(alertActions.success('Transactions Success'));
          }
          // var getdata=response.json();
          // console.log(response,response.json(),"getdata123");
          // dispatch(success(response));
          // if(response?.status==455){
          //   dispatchStore(userActions.logout('auto',"Your session has expired. Kindly login again.") as any);
          // }else{
          //   console.log(getdata,response,response.json(),"getdata123");
          // dispatch(success(getdata));
          // dispatch(alertActions.success('Transactions Success'));
          // console.log(response, "szfszfszffff");

          // }
        },
        (error) => {
          console.log(error, "szfszfszffff");
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: settingConstants.GETUSERDETAIL_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.GETUSERDETAIL_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: settingConstants.GETUSERDETAIL_FAILURE };
  }
}
function updateUser(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    settingService
      .updateUser(req)
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
            toast.success(transaction.message, { duration: 8000 });
            window.location.href = '/settings/team';
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
            toast.error(transaction.message, { duration: 8000 });
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() {
    return { type: settingConstants.UPDATEUSER_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.UPDATEUSER_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: settingConstants.UPDATEUSER_FAILURE };
  }
}
function findUserDetail(req: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dispatch(request(req));
    dispatch(alertActions.clear());
    settingService
      .findUserDetail(req)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          dispatch(success(response.data));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request(data: any) {
    return { type: settingConstants.FINDUSERDETAIL_REQUEST, data };
  }
  function success(data: any) {
    return { type: settingConstants.FINDUSERDETAIL_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: settingConstants.FINDUSERDETAIL_FAILURE };
  }
}
function getTeamById(req: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dispatch(request(req));
    dispatch(alertActions.clear());
    settingService
      .getTeamById(req)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          dispatch(success(response.data));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request(data: any) {
    return { type: settingConstants.GETTEAMBYID_REQUEST, data };
  }
  function success(data: any) {
    return { type: settingConstants.GETTEAMBYID_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: settingConstants.GETTEAMBYID_FAILURE };
  }
}
function deleteInactiveUser(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    settingService
      .deleteInactiveUser(req)
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
            toast.success(transaction.message, { duration: 8000 });
            dispatch(settingActions.getTeamDocuments() as any);
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
            toast.error(transaction.message, { duration: 8000 });
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(req: any) {
    return { type: settingConstants.DELETINACTIVEEUSER_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.DELETINACTIVEEUSER_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: settingConstants.DELETINACTIVEEUSER_FAILURE };
  }
}
function Team_cerete(data: any, ONsave: any, Vlidesa: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    settingService
      .Team_cerete(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
            dispatch(settingActions.getTeamDocuments() as any);
            ONsave();
          } else {
            console.log(response, 'responseresponse');
            dispatch(failure(response.message));
            Vlidesa();
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: settingConstants.CERETE_TEAM_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.CERETE_TEAM_SUCCESS, data };
  }
  function failure(error: string) {
    return { type: settingConstants.CERETE_TEAM_FAILURE, error };
  }
}
function deleteInactiveTaem(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    settingService
      .deleteInactiveTaem(req)
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
            toast.success(transaction.message, { duration: 8000 });
            dispatch(settingActions.getTeamDocuments() as any);
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
            toast.error(transaction.message, { duration: 8000 });
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(req: any) {
    return { type: settingConstants.DELETINACTIVEEUSER_TEAM_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.DELETINACTIVEEUSER_TEAM_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: settingConstants.DELETINACTIVEEUSER_TEAM_FAILURE };
  }
}
function updateTeam(req: any, id: any, ONsave: any, Vlidesa: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    settingService
      .updateTeam(req, id)
      .then((data) => {
        return data.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            dispatch(settingActions.getTeamDocuments() as any);
            dispatch(success(transaction));
            dispatch(alertActions.success('Transactions Success'));
            ONsave();
          } else {
            dispatch(failure(transaction.message));
            dispatch(alertActions.error(transaction.message));
            Vlidesa();
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() {
    return { type: settingConstants.UPDATETEAM_REQUEST };
  }
  function success(data: any) {
    return { type: settingConstants.UPDATETEAM_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: settingConstants.UPDATETEAM_FAILURE };
  }
}
