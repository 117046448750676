import React, { useCallback, useState, useEffect } from "react";
import PageHeader from "../PageHeader";
import { Dropdown, DropdownMenuItem, IconButton } from "@team-monite/ui-kit-react";
import { TagsTable, TagFormModal } from "@team-monite/ui-widgets-react";
import "./ApiKey.scss";
import { Button } from "react-bootstrap";
import { getUser, isUserAuthenticated } from "../../../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { entityActions } from "../../../../_actions/entity.actions";
import { Link, useNavigate } from "react-router-dom";
import { Setting_icon } from "./Setting_icon";
import { paymentJourneyActions } from "../../../../_actions/paymentJourney.actions";
import { selectPaymentJourneyData } from "../../../../_reducers/paymentJourney.reducer";
import Teabal_data from './tebal_apikey';
export const ApiKey = () => {
  const dispatch = useDispatch();

  const [apikeyList, setApikeyList] = React.useState(["1"])
  let navigate = useNavigate();
  const [render, setRender] = useState(true)
  const [addexpressSuccrender, setaddexpressSuccrender] = useState(true);
  const [trans, settrans] = useState<any>();
  const [data_enter, setData_enter] = useState<any>()
  const [apis_call, setApis_call] = useState(false)
  console.log(data_enter, 'data_enterdata_enter');

  const lists = [{ name: "Testing", id: 1 }, { name: "credentials", id: 2 }, { name: "credentials", id: 2 }]
  useEffect(() => {
    if (render && isUserAuthenticated()) {
      setRender(false);
      dispatch(paymentJourneyActions.getIntegration_active() as any)
    }
  }, [render, isUserAuthenticated])
  const dataList = useSelector((state) => selectPaymentJourneyData(state))
  console.log(dataList, 'dataListdataList');
  useEffect(() => {
    if (addexpressSuccrender && dataList?.GETINTEGRATION_SUCCESS !== undefined) {
      setaddexpressSuccrender(false);
      settrans(dataList.GETINTEGRATION_SUCCESS);
    }
  }, [dataList.GETINTEGRATION_SUCCESS, addexpressSuccrender]);
  return (
    <>
      {apis_call == true ?
        <Teabal_data props={data_enter} />
        : <>
          <div className="tags">
            <PageHeader
              title="Integration"
              extra={
                    <Button onClick={() => { navigate('/settings/createapikey'); }} color="#025041" className="tags__save-btn" >
                      Create New
                    </Button>
                  }
            />
            <>
              {!!trans && trans.length > 0 ?
                <div className="calss_tage_ajajahhahha" style={{ display: 'flex', padding: '10px 43px' }}>

                  <div className={'create_intigretion'} style={{
                    maxHeight: "100%",
                    padding: "40px 60px",
                    height: "fit-content"
                  }}>
                    <p className='list_heading_create'>
                      Integration
                    </p>
                    <div className="list_div_intigretion">
                      {!!trans && trans.map((item: any, index: any) => {
                        return (
                          <div className="box_intigretion"

                          >
                            <div key={index} className="create_rows">
                              <p className='row_text'>{item.title}</p>
                              <div style={{
                                display: "flex",
                                columnGap: "10px"
                              }}>
                                <button className={'button_api_credential'}
                                  onClick={() => {
                                
                                    navigate("/settings/Credentials?id=" + item.id, { replace: true, state: { BILLSBULK: item.id } })
                                    setApis_call(true)
                                  }}>
                                  API Credentials
                                </button>
                              </div>
                            </div>
                          </div>
                        )
                      })}</div>
                  </div>
                  </div> :
                <div className="calss_tage_ajajahhahha" style={{ display: 'flex', height: '94vh', padding: '10px 43px' }}>

                  <div className={'create_intigretion'}>
                    <p className='heading_create'>
                      Create your first integration

                    </p>
                    <p className='description_create'>
                      Let's create your first api credential for current project to give your developers access to it.
                    </p>
                    <Button onClick={() => { navigate('/settings/createapikey'); }} color="#025041" className="tags__save-btn" >
                      Create Integration
                    </Button>
                  </div>
                  </div>}
                  </>
          {/* </div> */}
        </div>
    </>
      }
    </>
  );
};
