import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import styles from './styles.module.scss';
import { history } from '../../_helpers';
import Login from '../auth/login/Login';
import { ForgotPassword } from '../auth/login/ForgotPassword';
import NavPage from './Layout/NavPage';
import { Compliance } from './Layout/compliance/Compliance';
import { VerifyEmail } from '../auth/verify-email/VerifyEmail';
import { Updatepassword } from '../auth/updatepassword/updatepassword';
import { Changepassword } from '../auth/changepassword/changepassword';
import { Payables } from './Layout/Payables/Payables';
import { Counterparty } from './Layout/Receivables/Receivables';
import { Settings } from './Layout/Settings/Settings';
import { ApprovalPolicies } from './Layout/Settings/ApprovalPolicies';
import { Counterparts } from './Layout/Counterparts/Counterparts';
import { Wallet } from './Layout/Wallet/Wallet';
import { Balance } from './Layout/Balance/Balance';
import { Tags } from './Layout/Tags/Tags';
import { ApiKey } from './Layout/ApiKey/ApiKey';
import { CreateApiKey } from './Layout/ApiKey/CreateApiKey';
import { Gethelp } from './Layout/Gethelp/Gethelp';
import { dispatchStore } from '../../Store/store';
import { entityActions, userActions } from '../../_actions';
import { CompanyDetails } from './Layout/Settings/CompanyDetails';
// import { UserDetail } from './Layout/Settings/UserDetails';
import 'bootstrap/dist/css/bootstrap.css';
import { Bill } from './Layout/Bill/Bill';
import { BillsApproval } from './Layout/Bill/BillsApproval';
import { PaymentJourneyPage } from './Layout/PaymentJourney/PaymentJourney';
import { AccountManagement } from './Layout/Settings/components/AccountManagement';
import { Profile } from './Layout/Settings/components/Profile';
import { Verification } from './Layout/Settings/components/Profile/Profile';
import User_Management from './Layout/Settings/components/Profile/User_Management';
import Team_table from './Layout/Settings/components/Profile/Team_table';
import Budget from '../app/Layout/budget/budget';
import IdelTimer from './IdelTimer';
import Bulktebal from './Layout/Bill/components/csvfile/Bulktebal';
import Create_Account from '../auth/Create_Account/Create_Account';
import New_create_account from '../auth/Create_Account/new_create_account';
import { Users } from '../../../src/features/app/Layout/Settings/components/Users';
import Accessibility from './accessibility';
import { Contact_Us } from '../app/Layout/Gethelp/Contact_Us';
import Redayrect_page from '../app/Layout/Gethelp/redayrect';
import Credentials from '../app/Layout/ApiKey/tebal_apikey';
import Create_New_Budget from '../app/Layout/budget/Create_New_Budget';
import { TourProvider } from '@reactour/tour';
import { getUser, isUserAuthenticated } from '../../_helpers';
import Edit_budget from '../app/Layout/budget/edit_Budget';
import Bulk_Payments from './Layout/Bulk_Payments/Bulk_Payments';
import BillsApproval_bulk from './Layout/Bulk_Payments/approve_payment';
import { CereteCounterparts } from './Layout/Counterparts/cereteCounterparts';
import Saler from './Layout/Sales/Sales';
import Create_your_invoice from '../auth/Create_your_invoice/Create_your_invoice';
import Integrations from './Layout/Sales/integrations_page';
import All_user from './Layout/Settings/components/Profile/all_user';
import Pay from './Layout/Pay/Pay';
import Payment_Link from './Layout/Payment_Link/Payment_Link';
import Detels_payment from './Layout/Payment_Link/detels_payment';
import CeretePaymentLink from './Layout/Payment_Link/CeretePaymentLink';
import VerifyIdentity from './Layout/Settings/components/Profile/components/AccountSetup/VerifyIdentity';
import SetupFailed from './Layout/Settings/components/Profile/components/AccountSetup/Setupfailed';
import DocumentUpload from './Layout/Settings/components/Profile/components/AccountSetup/DocumentUpload';
import AccountSetup from './Layout/Settings/components/Profile/components/AccountSetup/AccountSetup';
import { useNavigate } from 'react-router-dom';
import Login_change from '../auth/login/Login_change';
import NewRegister from '../auth/register/NewRegister';
import Entity from './Layout/entity/NewEntity';
import NewCreate_Account_from from '../auth/Create_Account/new_create_account';
import { Create_Account_from } from '../auth/Create_Account/Create_Account_from';
import { DashboardV3 } from './Layout/Dashboard/DashboardV3';
import ApiDocumentation from './Layout/ApiDocumentation/ApiDocumentation';
import Auth_Layout from '../auth/auth_layout/auth_layout';
import { useSelector } from 'react-redux';
import { selectAllData } from '../../_reducers/entity.reducer';
import NewEntity from '../auth/entity/NewEntity';
import SelectCurrency from '../auth/selectCurrency';
import ReviewInformation from '../auth/reviewInformation';
import AccountSetupCompleted from '../auth/Account-setup-completed';
import RegisterV2 from '../auth/register/Registerv2';

function App() {
  // const userDetails = localStorage.getItem('user')
  const [isTimeout, setIsTimeout] = useState(false);
  const userDetails = localStorage.getItem('user');
  const [environmentLoader, setEnvironmentLoader] = useState(false);
  const [setpNumberAuth, setSetpNumberAuth] = useState(1);
  const [setpNumberAuthtwo, setSetpNumberAuthtwo] = useState(1);
  const [renderGet, setRenderGet] = useState(true);
  const [stepObj, setStepObj] = useState<any>();
  const data = useSelector((state) => selectAllData(state));


  const updateStep = (step: any, forward: any, refresh?: any) => {
    console.log(step, 'stepstepstep');
    var body = {
      "step": step,
      "forward": forward
    }

    setEnvironmentLoader(true)
    dispatchStore(entityActions.setCurrentStepAction(body, forward, setEnvironmentLoader));

    getStep();
  };

  const getStep = () => {
    // setEnvironmentLoader(true)
    dispatchStore(entityActions.getCurrentStepAction(setRenderGet));
  };

  useEffect(() => {
    if (!!user) {
      getStep();
    }
  }, [setpNumberAuth]);
  React.useEffect(() => {
    if (data?.GET_CURRENT_STEP_SUCCESS !== undefined && renderGet) {
      setRenderGet(false);
      const dataList = data?.GET_CURRENT_STEP_SUCCESS?.data;
      setStepObj(dataList);
      // setTimeout(() => {
      //   setEnvironmentLoader(false)
      // }, 2000);
    }
  }, [data?.GET_CURRENT_STEP_SUCCESS, renderGet]);
  React.useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault();
      console.log('beforeunload event triggered');
      const sesionTime: any = localStorage.getItem('sesionTime');
      const nowtime = Date.now();
      if (!!sesionTime && sesionTime != 0) {
        if (Number(sesionTime) < nowtime) {
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          localStorage.removeItem('_expiredTime');
          window.location.href = '/login';
        }
      }
      const expiredTime: any = Number(Date.now() + 24 * 60 * 60 * 1000);
      localStorage.setItem('sesionTime', expiredTime);
    };

    // window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  React.useEffect(() => {
    const timer = new IdelTimer({
      timeout: 600,
      onTimeout: () => {
        localStorage.removeItem('_expiredTime');
        dispatchStore(userActions.logout('auto', "Your session has expired. Kindly login again.") as any);
        setIsTimeout(true);
      },
      onExpired: () => {
        setIsTimeout(true);
      },
    });
    return () => {
      timer.cleanUp();
    };
  }, []);

  const user = getUser()?.data ?? '';
  console.log(user, 'userjlllll');

  const steps =
    !!user &&
      user.role_name &&
      (user.role_name == 'Accountant' ||
        user.role_name == 'Admin' ||
        user.role_name == 'Director')
      ? [
        {
          selector: '#fast_list_log',
          content:
            'Gain immediate insights into your financial well-being through real-time data widgets and essential highlights.',
        },
        {
          selector: '#fast_secend_log',
          content:
            'Effortlessly include, categorize, and oversee individual or bulk payees for smooth management.  We also have a set of predefined payees for your convenience.',
        },
        {
          selector: '#thred_log',
          content:
            'Never overlook a payment again. Seamlessly arrange your outflows by effortlessly adding, scheduling, and monitoring bills.',
        },
        {
          selector: '#aporuwal_data',
          content:
            'Manage bill and payment approvals efficiently. Review and authorize outflows and keep control over your financial transactions in one place.',
        },
        {
          selector: '#invoice_porduct_tool',
          content:
            'Effortlessly create professional invoices with our user-friendly Invoice Generator tool.',
        },
        {
          selector: '#Balance_list',
          content:
            'Access detailed transaction information and oversee your account activity.',
        },
        {
          selector: '#Budgeting_list',
          content:
            'Effortlessly plan and monitor your outflows. Establish budgets, allocate categories, and compare actual spending against your objectives.',
        },
        {
          selector: '#Workspace_list',
          content:
            'Nurture collaboration within your workspace. Efficiently organize your business operations with distinct roles and permissions. Verify your business and manage your credentials all at one place.',
        },
        {
          selector: '#Get_Help_list',
          content:
            "Require assistance? Utilize our 'Get Help' feature for swift customer support, frequently asked questions, and enriching resources.",
        },
        {
          selector: '#Logout',
          content:
            'Conclude your session and safeguard your data using our Logout feature.',
        },
        {
          selector: '#totalBalance_header',
          content: 'See your overall financial standing at a glance',
        },
        {
          selector: '#Dashboard_Top_up',
          content:
            'Enhance your financial standing effortlessly by adding funds to your account with our convenient Top-Up feature.',
        },
        {
          selector: '#Dashboard_Make_Payment',
          content:
            'Simplify transactions with our Make Payment feature. Streamline payments and maintain control over your finances.      ',
        },
        {
          selector: '#Total_Transactions_header',
          content: ' Manage and track your paid and outstanding bills.',
        },
        {
          selector: '#Total_Bills',
          content:
            'Effortlessly manage your outflows with a comprehensive view using the View All Bills feature. Stay organized while effortlessly tracking your bills.',
        },
        {
          selector: '#Recent_Transactions_header',
          content: 'View your most recent transactions at a glance.',
        },
        {
          selector: '#Current_Month_Activity',
          content:
            'Access specific financial data by selecting desired dates for the current month.',
        },
        {
          selector: '#Budget_header',
          content:
            'Compare your budgeted outflows with your actual spending at a glance',
        },
        {
          selector: '#Expense_split_header',
          content:
            'Explore your spending patterns with a breakdown of outflows by category.',
        },
        {
          selector: '#payees_split',
          content:
            'Detailed breakdown of your outflows by payees for better financial insight.        ',
        },
        {
          selector: '#payment_method',
          content:
            'Track your spending based on different payment methods for a clearer financial overview',
        },
        // ...
      ]
      : [
        {
          selector: '#fast_list_log',
          content:
            'Gain immediate insights into your financial well-being through real-time data widgets and essential highlights.',
        },
        {
          selector: '#fast_secend_log',
          content:
            'Effortlessly include, categorize, and oversee individual or bulk payees for smooth management.  We also have a set of predefined payees for your convenience.',
        },
        {
          selector: '#thred_log',
          content:
            'Never overlook a payment again. Seamlessly arrange your outflows by effortlessly adding, scheduling, and monitoring bills.',
        },
        {
          selector: '#aporuwal_data',
          content:
            'Manage bill and payment approvals efficiently. Review and authorize outflows and keep control over your financial transactions in one place.',
        },
        {
          selector: '#Balance_list',
          content:
            'Access detailed transaction information and oversee your account activity.',
        },
        {
          selector: '#Budgeting_list',
          content:
            'Effortlessly plan and monitor your outflows. Establish budgets, allocate categories, and compare actual spending against your objectives.',
        },
        {
          selector: '#Workspace_list',
          content:
            'Nurture collaboration within your workspace. Efficiently organize your business operations with distinct roles and permissions. Verify your business and manage your credentials all at one place.',
        },
        {
          selector: '#Get_Help_list',
          content:
            "Require assistance? Utilize our 'Get Help' feature for swift customer support, frequently asked questions, and enriching resources.",
        },
        {
          selector: '#Logout',
          content:
            'Conclude your session and safeguard your data using our Logout feature.',
        },
        {
          selector: '#totalBalance_header',
          content: 'See your overall financial standing at a glance',
        },
        {
          selector: '#Total_Transactions_header',
          content: ' Manage and track your paid and outstanding bills.',
        },
        {
          selector: '#Total_Bills',
          content:
            'Effortlessly manage your outflows with a comprehensive view using the View All Bills feature. Stay organized while effortlessly tracking your bills.',
        },
        {
          selector: '#Recent_Transactions_header',
          content: 'View your most recent transactions at a glance.',
        },
        {
          selector: '#Current_Month_Activity',
          content:
            'Access specific financial data by selecting desired dates for the current month.',
        },
        {
          selector: '#Budget_header',
          content:
            'Compare your budgeted outflows with your actual spending at a glance',
        },
        {
          selector: '#Expense_split_header',
          content:
            'Explore your spending patterns with a breakdown of outflows by category.',
        },
        {
          selector: '#payees_split',
          content:
            'Detailed breakdown of your outflows by payees for better financial insight.        ',
        },
        {
          selector: '#payment_method',
          content:
            'Track your spending based on different payment methods for a clearer financial overview',
        },
      ];
  const steps_two = [
    {
      selector: '#fast_list_log',
      content:
        'Gain immediate insights into your financial well-being through real-time data widgets and essential highlights.',
    },
    {
      selector: '#fast_secend_log',
      content:
        'Effortlessly include, categorize, and oversee individual or bulk payees for smooth management.  We also have a set of predefined payees for your convenience.',
    },
    {
      selector: '#thred_log',
      content:
        'Never overlook a payment again. Seamlessly arrange your outflows by effortlessly adding, scheduling, and monitoring bills.',
    },
    {
      selector: '#thred_log',
      content:
        'Never overlook a payment again. Seamlessly arrange your outflows by effortlessly adding, scheduling, and monitoring bills.',
    },
    {
      selector: '#Workspace_list',
      content:
        'Nurture collaboration within your workspace. Efficiently organize your business operations with distinct roles and permissions. Verify your business and manage your credentials all at one place.',
    },
    {
      selector: '#totalBalance_header',
      content: 'See your overall financial standing at a glance',
    },
    {
      selector: '#Total_Transactions_header',
      content: ' Manage and track your paid and outstanding bills.',
    },
    {
      selector: '#Total_Bills',
      content:
        'Effortlessly manage your outflows with a comprehensive view using the View All Bills feature. Stay organized while effortlessly tracking your bills.',
    },
    {
      selector: '#Recent_Transactions_header',
      content: 'View your most recent transactions at a glance.',
    },
    {
      selector: '#Current_Month_Activity',
      content:
        'Access specific financial data by selecting desired dates for the current month.',
    },
    {
      selector: '#Budget_header',
      content:
        'Compare your budgeted outflows with your actual spending at a glance',
    },
    {
      selector: '#Expense_split_header',
      content:
        'Explore your spending patterns with a breakdown of outflows by category.',
    },
    {
      selector: '#payees_split',
      content:
        'Detailed breakdown of your outflows by payees for better financial insight.        ',
    },
    {
      selector: '#payment_method',
      content:
        'Track your spending based on  different payment methods for a clearer financial overview',
    },
  ];
  const steps_three = [
    {
      selector: '#fast_list_log',
      content:
        'Gain immediate insights into your financial well-being through real-time data widgets and essential highlights.',
    },
    {
      selector: '#Balance_list',
      content:
        'Access detailed transaction information and oversee your account activity.',
    },
    {
      selector: '#Workspace_list',
      content:
        'Nurture collaboration within your workspace. Efficiently organize your business operations with distinct roles and permissions. Verify your business and manage your credentials all at one place.',
    },
    {
      selector: '#Get_Help_list',
      content:
        "Require assistance? Utilize our 'Get Help' feature for swift customer support, frequently asked questions, and enriching resources.",
    },
    {
      selector: '#totalBalance_header',
      content: 'See your overall financial standing at a glance',
    },
    {
      selector: '#Total_Transactions_header',
      content: ' Manage and track your paid and outstanding bills.',
    },
    {
      selector: '#Total_Bills',
      content:
        'Effortlessly manage your outflows with a comprehensive view using the View All Bills feature. Stay organized while effortlessly tracking your bills.',
    },
    {
      selector: '#Recent_Transactions_header',
      content: 'View your most recent transactions at a glance.',
    },
    {
      selector: '#Current_Month_Activity',
      content:
        'Access specific financial data by selecting desired dates for the current month.',
    },
    {
      selector: '#Budget_header',
      content:
        'Compare your budgeted outflows with your actual spending at a glance',
    },
    {
      selector: '#Expense_split_header',
      content:
        'Explore your spending patterns with a breakdown of outflows by category.',
    },
    {
      selector: '#payees_split',
      content:
        'Detailed breakdown of your outflows by payees for better financial insight.        ',
    },
    {
      selector: '#payment_method',
      content:
        'Track your spending based on  different payment methods for a clearer financial overview',
    },
    {
      selector: '#totalBalance_header',
      content: 'See your overall financial standing at a glance',
    },
    {
      selector: '#Total_Transactions_header',
      content: ' Manage and track your paid and outstanding bills.',
    },
    {
      selector: '#Total_Bills',
      content:
        'Effortlessly manage your outflows with a comprehensive view using the View All Bills feature. Stay organized while effortlessly tracking your bills.',
    },
    {
      selector: '#Recent_Transactions_header',
      content: 'View your most recent transactions at a glance.',
    },
    {
      selector: '#Current_Month_Activity',
      content:
        'Access specific financial data by selecting desired dates for the current month.',
    },
    {
      selector: '#Budget_header',
      content:
        'Compare your budgeted outflows with your actual spending at a glance',
    },
    {
      selector: '#Expense_split_header',
      content:
        'Explore your spending patterns with a breakdown of outflows by category.',
    },
    {
      selector: '#payees_split',
      content:
        'Detailed breakdown of your outflows by payees for better financial insight.        ',
    },
    {
      selector: '#payment_method',
      content:
        'Track your spending based on  different payment methods for a clearer financial overview',
    },
  ];
  const [overlayVisible, setOverlayVisible] = useState(false);
  console.log(overlayVisible, 'overlayVisible');

  // React.useEffect(() => {
  //   const disableContextMenu = (e:any) => {
  //     e.preventDefault();
  //   };

  //   window.addEventListener('contextmenu', disableContextMenu);

  //   return () => {
  //     window.removeEventListener('contextmenu', disableContextMenu);
  //   };
  // }, []);
  return (
    <>
      {/* {overlayVisible ? <div className="screenshot-overlay"></div>
        : */}
      <div className={styles.app}>
        <Accessibility d={document} />

        <Router>
          <Routes>
          <Route path="v2/login" element={<Login_change path={'/settings/account-setup'} />} />
          <Route path="login" element={<Login_change path={'/v2/account-setup'}  />} />
            <Route path="verify-email" element={<VerifyEmail />} />
            <Route path="update-password" element={<Updatepassword />} />
            <Route path="change-password" element={<Changepassword />} />
            <Route path="invitation-to-niobi" element={<New_create_account />} />
            <Route path="new-login" element={<Login />} />
            <Route
              path="Create_Account_from"
              element={<Create_Account_from />}
            />
            <Route
              path="Create_your_invoice"
              element={<Create_your_invoice data={'logout'} />}
            />
            {/* setp 1 */}
            <Route
              path="v2/account-setup"
              element={
                <Auth_Layout environmentLoader={environmentLoader} setSetpNumberAuthapp={setSetpNumberAuth} updateStep={updateStep} setp={setpNumberAuth}>
                  <AccountSetup
                    setSetpNumberAuth={setSetpNumberAuth}
                    updateStep={updateStep}
                    path={'/v2/document-upload'}
                  />
                </Auth_Layout>
              }
            />
            {/* setp 2  setp 3*/}
            <Route
              path="v2/document-upload"
              element={
                <Auth_Layout environmentLoader={environmentLoader} setSetpNumberAuthapp={setSetpNumberAuth} updateStep={updateStep} setp={setpNumberAuth}>
                  <DocumentUpload
                    api="/v2/create-entity"
                    updateStep={updateStep}
                    path="/v2/entity"
                    // path="/v2/entity"
                    backpath="/v2/account-setup"
                    setSetpNumberAuth={setSetpNumberAuth}
                    onSave={() => {
                      console.log('On Save clicked');
                    }}
                  />
                </Auth_Layout>
              }
            />
            {/* setp 4 */}
            <Route
              path="v2/entity"
              element={
                <Auth_Layout environmentLoader={environmentLoader} setSetpNumberAuthapp={setSetpNumberAuth} updateStep={updateStep} setp={setpNumberAuth}>
                  <NewEntity
                    updateStep={updateStep}
                    setSetpNumberAuth={setSetpNumberAuth}
                    path={'/v2/currency'}
                    api="/v2/update-entity"
                  />
                </Auth_Layout>
              }
            />
            {/* setp 5 */}
            <Route
              path="v2/currency"
              element={
                <Auth_Layout environmentLoader={environmentLoader} setSetpNumberAuthapp={setSetpNumberAuth} updateStep={updateStep} setp={setpNumberAuth}>
                  <SelectCurrency
                    updateStep={updateStep}
                    setSetpNumberAuth={setSetpNumberAuth}
                    path={'/v2/reviews'}
                  />
                </Auth_Layout>
              }
            />
            {/* setp 6 */}
            <Route
              path="v2/reviews"
              element={
                <Auth_Layout environmentLoader={environmentLoader} updateStep={updateStep} setSetpNumberAuthapp={setSetpNumberAuth} setp={setpNumberAuth}>
                  <ReviewInformation
                    setSetpNumberAuth={setSetpNumberAuth}
                    updateStep={updateStep}
                    path={'/v2/verify-identity'}
                  />
                </Auth_Layout>
              }
            />
            {/* setp 7 */}
            <Route
              path="v2/verify-identity"
              element={
                <Auth_Layout environmentLoader={environmentLoader} setSetpNumberAuthapp={setSetpNumberAuth} updateStep={updateStep} setp={setpNumberAuth}>
                  <VerifyIdentity
                    path="/v2/account-setup-completed"
                    setSetpNumberAuth={setSetpNumberAuth}
                    updateStep={updateStep}
                  />
                </Auth_Layout>
              }
            />
            <Route
              path="v2/account-setup-completed"
              element={
                <Auth_Layout environmentLoader={environmentLoader} setSetpNumberAuthapp={setSetpNumberAuth} updateStep={updateStep} setp={setpNumberAuth} sideBar={false}>
                  <AccountSetupCompleted />
                </Auth_Layout>
              }
            />
            <Route path="entity" element={<Entity />} />
            <Route path="pay/:entityid/:shortcode" element={<Pay />} />
            <Route
              path="/"
              element={
                <TourProvider
                  steps={
                    !!user && user.role_name && user.role_name == 'Associate'
                      ? steps_two
                      : !!user &&
                        user.role_name &&
                        user.role_name == 'Developer'
                        ? steps_three
                        : steps
                  }
                  position="right"
                  styles={{
                    popover: (base) => ({
                      ...base,
                      '--reactour-accent': '#025041',
                      borderRadius: 20,
                    }),
                  }}
                // afterOpen={disableBody}
                // beforeClose={enableBody}
                >
                  <NavPage />{' '}
                </TourProvider>
              }
            >
              <Route path="payment-journey" element={<PaymentJourneyPage />} />
              <Route path="compliance" element={<Compliance />} />
              <Route path="payables" element={<Payables />} />
              <Route
                path="ceretecounterparts"
                element={<CereteCounterparts />}
              />
              <Route path="counterparts" element={<Counterparts />} />
              <Route path="wallet" element={<Wallet />} />
              <Route
                path="dashboard"
                element={
                  <TourProvider
                    steps={
                      !!user && user.role_name && user.role_name == 'Associate'
                        ? steps_two
                        : !!user &&
                          user.role_name &&
                          user.role_name == 'Developer'
                          ? steps_three
                          : steps
                    }
                    position="right"
                    styles={{
                      popover: (base) => ({
                        ...base,
                        '--reactour-accent': '#025041',
                        borderRadius: 20,
                      }),
                    }}
                  >
                    <DashboardV3 />
                  </TourProvider>
                }
              />

              <Route path="budget" element={<Budget />} />
              <Route path="create_new_budget" element={<Create_New_Budget />} />
              <Route path="edit_budget" element={<Edit_budget />} />
              <Route path="bills" element={<Bill />} />
              <Route path="bills-approval" element={<BillsApproval />} />
              {/*<Route path="counterparts" element={<Counterparts />} />*/}
              <Route path="balance" element={<Balance />} />
              <Route path="Bulk_Payments" element={<Bulk_Payments />} />
              <Route
                path="invoice"
                element={<Create_your_invoice data={'login'} />}
              />
              {/* <Route path="Pesalink_filed" element={<Pesalink_filed />} /> */}
              <Route path="Sales" element={<Saler />} />
              {/* <Route path='ChIntegrations' element={<Integrations />} /> */}
              <Route path="approvals">
                <Route path="bills-approval" element={<BillsApproval />} />
                <Route
                  path="approve_payment"
                  element={<BillsApproval_bulk />}
                />
              </Route>
              <Route path="gethelp">
                <Route path="Raise_a_Ticket" element={<Gethelp />} />
                <Route path="FAQs" element={<Contact_Us />} />
                <Route path="contact_us" element={<Redayrect_page />} />

              </Route>
              <Route path="Bulktebal" element={<Bulktebal />} />
              <Route path="settings">
                <Route path="organisation" element={<CompanyDetails />} />
                <Route path="profile" element={<Profile />} />
                <Route path="ApiDocumentation" element={<ApiDocumentation />} />
                <Route path="UserDetail" element={<Users />} />
                <Route path="verification" element={<Verification />} />
                <Route path="account-setup" element={<AccountSetup />} />
                {/* <Route path="verify-identity" element={<VerifyIdentity />} /> */}
                <Route
                  path="document-upload"
                  element={
                    <DocumentUpload
                      onSave={() => {
                        console.log('On Save clicked');
                      }}
                    />
                  }
                />

                <Route path="verify-identity" element={<VerifyIdentity />} />
                <Route path="setup-failed" element={<SetupFailed />} />
                <Route path="team" element={<Team_table />} />
                <Route path="team_user" element={<User_Management />} />
                <Route path="Payment_Link" element={<Payment_Link />} />
                <Route
                  path="Detels_payment_Link"
                  element={<Detels_payment />}
                />

                <Route
                  path="CeretePaymentLink"
                  element={<CeretePaymentLink />}
                />
                <Route path="All-user" element={<All_user />} />
                <Route path="categories" element={<Tags />} />
                <Route path="apikey" element={<ApiKey />} />
                <Route path="Credentials" element={<Credentials />} />
                <Route path="createapikey" element={<CreateApiKey />} />
                {/*<Route path="company-details" element={<CompanyDetails />} />
              <Route path="user-details" element={<UserDetail />} /> */}
              </Route>
              <Route path="payables" element={<Payables />} />
            </Route>

            <Route path="v2/register" element={<NewRegister api="/register/app" path="/entity" />} />
            <Route
              path="register"
              element={
                <RegisterV2 api="/register/portal" path="/v2/account-setup" />
              }
            />
            <Route path="forgotpassword" element={<ForgotPassword />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
